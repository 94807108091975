import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Header from 'components/general/Header';
import Footer from 'components/general/Footer';
import { SearchProvider } from 'components/utils/contexts/search/Context';

const useStyles = makeStyles(theme => {
  const footerHeight = 180;
  const padding = 10;
  return {
    header: {
      zIndex: theme.zIndex.modal + 1,
      position: 'sticky',
      top: 0,
    },
    footer: {
      minHeight: `${footerHeight}px`
    },
    innerContainer: {
      minHeight: `calc(100vh - ${footerHeight + (padding * 2)}px)`,
    }
  };
});

function PrivateLayout({ children }) {
  const classes = useStyles();
  return (
    <SearchProvider>
      <div className="private-container">
        <div className={clsx(classes.header)}>
          <Header />
        </div>
        <div className={classes.innerContainer}>
          {children}
        </div>
        <div className={clsx(classes.footer)}>
          <Footer />
        </div>
      </div >
    </SearchProvider>
  );
}

PrivateLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PrivateLayout;