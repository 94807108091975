import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Icon, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    margin: 0
  },
  root: {
    '&:hover': {
      color: theme.palette.secondary.main
    }
  }
}));

function FavoriteButton({ name, color, checked, disabled, onChange }) {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  return (
    <FormControlLabel
      disabled={disabled}
      classes={{ root: classes.formControlLabel }}
      control={
        <Checkbox
          classes={{root: classes.root}}
          checked={checked}
          onChange={onChange}
          name={name}
          color={color}
          icon={
            <Icon>{hover ? 'favorite' : 'favorite_border'}</Icon>
          }
          checkedIcon={<Icon>{hover ? 'favorite_border' : 'favorite'}</Icon>}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
      }
    />
  );
}

FavoriteButton.defaultProps = {
  name: 'checkbox',
  color: 'primary',
  checked: false,
  disabled: false,
};

FavoriteButton.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default FavoriteButton;