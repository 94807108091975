import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Button from 'components/form/inputs/Button';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: () => ({
        backgroundImage: 'url(\'assets/encartcommerce-desktop.png\')',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: '60px 25px',
        color: 'white',
        textAlign: 'center',
        marginTop: 40,
        marginBottom: 40,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            backgroundImage: 'url(\'assets/encartcommerce-mobile.png\')',
            padding: '25px 20px 25px 20px',
            backgroundRepeat: 'repeat',
            backgroundSize: 'auto'
        }
    }),
}));

function Commercial() {
    const classes = useStyles();
    const { t } = useTranslation('main');
    return (
        <Paper className={clsx(classes.root)}>
            <Grid item>
                <Typography variant="body1">
                    <b>{t('commercial.project')}</b> {t('commercial.marketing')}
                </Typography>
            </Grid>
            <Grid item style={{marginTop:10}}>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    label={t('commercial.meeting')}
                    onClick={() => { window.open('https://www.kimpleapp.com/contact/'); }}
                />
            </Grid>
        </Paper>
  );
}

Commercial.defaultProps = {
};

Commercial.propTypes = {
};

export default Commercial;