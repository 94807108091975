import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  loaderContainer: {
    position: 'relative'
  },
  progressCircle: {
    color: '#cecece'
  },
  logo: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    height: '100%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)'
  }
}));

function Loader() {
  const classes = useStyles();
  return (
    <Grid item className={classes.loaderContainer}>
      <CircularProgress size={120} classes={{root: classes.progressCircle}}/>
      <img src={`${process.env.PUBLIC_URL}/assets/kimple_alt.svg`} className={classes.logo} alt="logo" />
    </Grid>
  );
}

export default Loader;
