import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Icon, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  button: ({ color, outline }) => ({
    margin: '15px 6px',
    backgroundColor: !outline && `${theme.palette[color].main}`,
    color: outline ? `${theme.palette[color].main}` : 'white',
    border: outline && `1px solid ${theme.palette[color].main}`,
    '&:hover': {
      backgroundColor: `${theme.palette[color].dark}`,
    }
  })
}));

function CustomIconButton(props) {
  const { id, color, action, disabled, icon, outline, className, tooltip } = props;
  const classes = useStyles({ color, action, disabled, icon, outline });
  return (

    <Tooltip title={tooltip}>
      <IconButton
        id={id}
        className={`${classes.button} ${className}`}
        color={color}
        onClick={action}
        disabled={disabled}
      >
        <Icon>{icon}</Icon>
      </IconButton>
    </Tooltip>
  );
}

CustomIconButton.defaultProps = {
  id: '',
  className: '',
  color: 'default',
  icon: '',
  action: null,
  disabled: false,
  outline: false,
  tooltip: '',
};

CustomIconButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  action: PropTypes.func,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default CustomIconButton;