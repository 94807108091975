/* eslint-disable no-underscore-dangle */
import axios from 'axios';

import authService from 'services/auth';
import store from 'config/store';
import routes from 'config/routes';


function removeTrailingSlash(str) {
  return str.endsWith('/') ? str.slice(0, -1) : str;
}

export const noAuth = axios.create({
  baseURL: `${removeTrailingSlash(process.env.REACT_APP_API_BASE_URL)}`,
  headers: {
    'Content-Type': 'application/json',
  }
});

export const withAuth = axios.create({
  baseURL: `${removeTrailingSlash(process.env.REACT_APP_API_BASE_URL)}/v2`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

// Intercept request
withAuth.interceptors.request.use((config) => {
  // Get token and add it to the Authorization header
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Intercept the response
 withAuth.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401) {
    const sessionAuth = store.get('auth');
    const { refreshToken } = sessionAuth;
    try {
      const refreshTokenResponse = await authService.refreshToken(refreshToken);
      const refreshTokenData = refreshTokenResponse.data.data;
      const { username, token, expire } = refreshTokenData;
      const newRefreshToken = refreshTokenData.refresh_token;
      const newRefreshTokenExpiration = refreshTokenData.refresh_token_expire;
      store.set('auth', { username, token, expire, refreshToken: newRefreshToken, refreshTokenExpire: newRefreshTokenExpiration });
      withAuth.defaults.headers.common.Authorization = `Bearer ${token}`;
      originalRequest.headers.Authorization = `Bearer ${token}`;
      return withAuth(originalRequest);
    } catch(err) {
      store.destroy();
      window.location.href = routes.LOGIN;
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
}); 

const headers = { 'Content-Type': 'application/json', Timezone: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ?? 'Europe/Paris' };
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers
});


export const authInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers
  });


export default { noAuth, withAuth, authInstance };
