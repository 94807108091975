import React from 'react';

function NotFound() {
  return (
    <div>
      <h2>Custom Header</h2>
      <h1>Not Found !</h1>
      <h2>Custom Footer</h2>
    </div>
  );
}

export default NotFound;
