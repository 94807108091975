import React, { useEffect, useCallback } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import authService from 'services/auth';

import OneTimePassword from 'components/pages/OneTimePassword';
import { useContextUser } from 'components/utils/contexts/user/Context';

const validationSchema = (t) => {
  return Yup.object().shape({
    mfa: Yup.string().required(t('requiredMfa'))
  });
};

const OneTimePasswordEnhancer = () => {
  const history = useHistory();
  const { shutdownIntercom, auth, getCurrentUser } = useContextUser();
  const { t } = useTranslation('login');

  const connexion = async () => {
    await getCurrentUser();
    history.push('/');
  }

  const handleSubmit = useCallback(async (values, { setErrors, setSubmitting, setFieldError }) => {
    try {
      await authService.mfaVerify(values.mfa, auth.username, auth.userHashId).then((res) => {
        if (res.data.data.state) {
          connexion()
        }
    })
    }catch(error)  {
        const { response } = error;
        if (response) {
          const { status } = response;
          if (status === 401) {
            setFieldError('mfa', 'Le code est incorrect');
            setErrors({ general: 'Le code est incorrect' });
          } else if (status === 400) {
            setErrors({ general: t('validations.unauthorized') });
          } else {
            setErrors({ general: t('validations.internalServerError') });
          }
        }
        setSubmitting(false);
 } }, []);

  useEffect(() => shutdownIntercom(), []);
  return (
    <Formik
      component={OneTimePassword}
      initialValues={{
        mfa: '',
      }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(t)}
    />
  );
};

export default OneTimePasswordEnhancer;