const particlesOptions = {
  background: {
    opacity: 0,
  },
  fpsLimit: 60,
  interactivity: {
    detectsOn: 'window',
    events: {
      resize: true,
      onDiv: {
        elementId: 'kimple-slideshow',
        enable: true,
        mode: 'bounce',
        type: 'rectangle'
      }
    }
  },
  particles: {
    move: {
      direction: 'none',
      enable: true,
      outMode: 'bounce',
      random: true,
      speed: .3,
      straight: false,
    },
    opacity: {
      value: 1,
      animation: {
        enable: true,
        sync: false,
        minimumValue: 0.5,
        speed: 1,
      },
      random: {
        enable: true,
        minimumValue: 0.2
      }
    },
    collisions: {
      enable: true,
      mode: 'bounce'
    },
    rotate: {
      animation: {
        enable: true,
        speed: 1,
      },
      direction: 'random',
      random: true
    },
    number: {
      value: 16,
    },
    shape: {
      type: 'image',
      image: [
        { src: '/assets/particles/large_circle.png' },
        { src: '/assets/particles/large_cross.png' },
        { src: '/assets/particles/large_line.png' },
        { src: '/assets/particles/large_wave.png' },
      ]
    },
    size: {
      value: 22,
      random: {
        enable: true,
        minimumValue: 15
      }
    },
  },
  detectRetina: true,
};

export default particlesOptions;