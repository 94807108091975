import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KimpleLogo from 'components/general/KimpleLogo';


const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '0px 0px 10px 0px',
    '& span': {
      paddingLeft: 0
    }
  },
  label: {
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

function CustomBlog(props) {
  const { className, link } = props;
  const classes = useStyles();
  return (
    <Chip
      classes={{
        root: classes.root,
        label: classes.label
      }}
      className={className}
      icon={<KimpleLogo size={25} color="inherit" />}
      label="Blog"
      color="secondary"
      onClick={(e) => { e.stopPropagation(); window.open(`${link}`); }}
    />
  );
}

CustomBlog.defaultProps = {
  className: '',
  link: '',
};

CustomBlog.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
};

export default CustomBlog;