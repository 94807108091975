/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { withRouter, Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import PrivateContainer from 'components/layouts/PrivateContainer';
import routes from 'config/routes';

const PrivateRoute = ({ component: Component, isAuthenticated, isAuthorized, ...otherProps }) => (
  <Route {...otherProps}>
    { isAuthenticated && isAuthorized
      ? (
        <PrivateContainer>
          <Component />
        </PrivateContainer>
      )
      : <Redirect to={isAuthenticated ? routes.BROWSE : routes.LOGIN} />}
  </Route>
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isAuthorized: PropTypes.bool.isRequired
};


export default withRouter(PrivateRoute);