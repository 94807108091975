const initialState = {
  search: null,
  user: {
    isGuest: false,
    data: null
  },
  auth: {
    expire: null,
    token: null,
    username: '',
    refreshToken: null,
    refreshTokenExpire: null,
    mfaEnabled: false
  }
};

function get(name) {
  const store = localStorage.getItem('kimple_store');
  if (store) {
    const parsedStore = JSON.parse(store);
    return parsedStore[name];
  }
  return null;
}

function set(name, obj) {
  const store = localStorage.getItem('kimple_store');
  if (store) {
    const parsedStore = JSON.parse(store);
    parsedStore[name] = {...parsedStore[name], ...obj };
    localStorage.setItem('kimple_store', JSON.stringify(parsedStore));
  }
}

function getWishlist() {
  const store = localStorage.getItem('kimple_store');
  if (store) {
    const parsedStore = JSON.parse(store);
    return parsedStore.search.wishlist;
  }
  return null;
}

function setWishlist(wishlist) {
  const store = localStorage.getItem('kimple_store');
  if (store) {
    const parsedStore = JSON.parse(store);
    parsedStore.search.wishlist = wishlist;
    localStorage.setItem('kimple_store', JSON.stringify(parsedStore));
  }
}

function reset() {
  localStorage.setItem('kimple_store', JSON.stringify(initialState));
}

function destroy() {
  localStorage.removeItem('kimple_store');
}

export default {
  reset,
  get,
  set,
  getWishlist,
  setWishlist,
  initialState,
  destroy
};
