import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
  alert: ({ severity }) => ({
    background: theme.palette[severity].main,
    color: 'white',
    padding: '12px 28px',
    borderRadius: 3
  }),
  icon: {
    marginRight: 10
  }
}));

function Alert({ message, severity }) {
  const classes = useStyles({ severity });
  const handleIcon = () => {
    switch (severity) {
      case 'error':
        return <ErrorOutlineIcon />;
      case 'warning':
        return <ReportProblemOutlinedIcon />;
      case 'success':
        return <CheckCircleOutlineIcon />;
      default:
        return <InfoOutlinedIcon />;
    }
  };
  return (
    <Grid container className={classes.alert} alignItems="center">
      <div className={classes.icon}>
        {handleIcon()}
      </div>
      <Typography variant="body1" color="inherit">{message}</Typography>
    </Grid>
  );
}

Alert.defaultProps = {
  message: '',
};

Alert.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.oneOf(['info', 'success', 'error', 'warning']).isRequired
};

export default Alert;