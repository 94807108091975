import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useSearchContext } from 'components/utils/contexts/search/Context';
import FilterCategory from 'components/pages/home/FilterCategory';
import Button from 'components/form/inputs/Button';

const useStyles = makeStyles((theme) => ({
  categoryGrid: {
    width: '100%',
    margin: '6px 0px'
  },
  removeFilters: {
    cursor: 'pointer',
    textDecoration: 'underline',
    margin: 17,
    '&:hover': {
      color: theme.palette.secondary.main
    }
  }
}));

function FiltersPanel({ closeDrawer }) {
  const { filters, toggleFilter, resetCategory, resetFilters, triggerAdvancedSearch } = useSearchContext();
  const [expanded, setExpanded] = useState('');
  const { t } = useTranslation('main');
  const classes = useStyles();
  const nbChecked = filters.reduce((acc, category) => acc + category.values.filter(filter => filter.checked).length, 0);

  return (
    <Grid container direction="column">
      {
        filters.map((category) => (
          <Grid item className={clsx(classes.categoryGrid)} key={`${category.name}-category`}>
            <FilterCategory
              title={t(`filters.${category.name}`)}
              name={category.name}
              filters={category.values}
              toggleFilter={toggleFilter}
              resetCategory={resetCategory}
              expanded={expanded === category.name}
              toggleAccordion={() => setExpanded(category.name === expanded ? '' : category.name)}
            />
          </Grid>
        ))
      }
      <Grid container justify="center">
        <Grid item xs={12} sm={5} md={12}>
          <Hidden mdUp>
            <Button type="button" variant="outlined" color="primary" label={t('filters.searchAction')} size="large" fullWidth
              onClick={(e) => {
                triggerAdvancedSearch();
                closeDrawer(e);
              }}
              style={{marginBottom: 5}}
            />
          </Hidden>
          {
            nbChecked > 0 && (
              <>
              <Hidden mdUp>
                <Button type="button" variant="contained" color="primary" label={t('filters.removeFilters')} size="large" fullWidth onClick={resetFilters} id="resetFiltersButton"/>
              </Hidden>
              <Hidden smDown>
                <Typography variant="subtitle2" color="textSecondary" className={clsx(classes.removeFilters)} onClick={resetFilters} id="resetFiltersButton">
                  {t('filters.removeFilters')}
                </Typography>
              </Hidden>
              </>
            )
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

FiltersPanel.defaultProps = {
  closeDrawer: () => {}
};

FiltersPanel.propTypes = {
  closeDrawer: PropTypes.func
};

export default FiltersPanel;