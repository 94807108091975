import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Container, Grid, Typography, Hidden, LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import StickyBox from 'react-sticky-box';

import ContestCard from 'components/pages/home/ContestCard';
import Preview from 'components/pages/Preview';
import Select from 'components/form/inputs/Select';
import Commercial from 'components/general/Commercial';
import routes from 'config/routes';
import Loader from 'components/general/Loader';
import Button from 'components/form/inputs/Button';
import BackToTop from 'components/general/BackToTop';

import { useSearchContext } from 'components/utils/contexts/search/Context';
import { useContextUser } from 'components/utils/contexts/user/Context';

import Filters from 'components/pages/home/Filters';

const OFFSET = 30;

function ContestsList({ width }) {
  const { t } = useTranslation('main');
  const { results, loading, error, initialized, defaultOptions, relatedContent, toggleFavorite, search, filters, sortBy } = useSearchContext();

  const nbChecked = filters.reduce((acc, category) => acc + category.values.filter(f => f.checked).length, 0);
  const { frequency } = relatedContent;
  const related = relatedContent.results;
  const { user } = useContextUser();
  const [currentHashId, setCurrentHashId] = useState(null);
  const [open, setOpen] = useState(false);
  const [infiniteScroll, setInfiniteScroll] = useState({ offset: OFFSET, fakeLoading: false });

  useEffect(() => {
    sortBy('publishedAt', 'desc');
  }, [initialized]);

  const loadMore = () => {
    setInfiniteScroll({ ...infiniteScroll, fakeLoading: true });
    setTimeout(() => setInfiniteScroll({ offset: infiniteScroll.offset + OFFSET, fakeLoading: false }), 500);
  };

  const handleClose = () => {
    if (open) {
      setOpen(false);
      document.body.classList.remove('noscroll');
    }
  };

  const handleToggle = (contestHashId, event) => {
    if (event.target.type !== 'checkbox') {
      setCurrentHashId(contestHashId);
      if (isMobile) {
        window.open(`${process.env.REACT_APP_SHORT_DOMAIN}/contest-${contestHashId}`);
      } else {
        setOpen(!open);
        document.body.classList.add('noscroll');
      }
    }
  };

  const changeContest = (offset) => {
    const key = results.findIndex(contest => contest.hashId === currentHashId);
    const newKey = key + offset;
    if (newKey >= 0 && newKey < results.length) {
      setCurrentHashId(results[newKey].hashId);
    } else if (newKey < 0) {
      setCurrentHashId(results[results.length - 1].hashId);
    } else if (newKey >= results.length) {
      setCurrentHashId(results[0].hashId);
    }
  };

  const contestPreview = results.find(contest => contest.hashId === currentHashId);
  const location = useLocation();

  const title = () => {
    if (search.term !== '' || nbChecked !== 0) return t('contest_list.search_results');
    if (location.pathname === routes.WISHLIST) return t('contest_list.wishlist');
    return t('contest_list.news');
  };

  const resultsCount = () => {
    if (search.term !== '' || nbChecked !== 0) {
      return (
        <Typography
          color="primary"
          variant="h6"
          component={isWidthDown('sm', width) ? 'h6' : 'span'}
          style={{ marginLeft: isWidthDown('sm', width) ? 0 : 8 }}
        >
          {t('contest_list.correspondingTemplates', { count: results.length })}
        </Typography>
      );
    }
    return null;
  };

  const displayRelatedContent = (index) => {
    const sponsor = related[index];
    return (
      <ContestCard
        title={sponsor.title}
        image={sponsor.cover_url}
        cta={sponsor.cta_label}
        type={2}
        onClick={() => window.open(sponsor.content_link)}
      />
    );
  };

  if (!loading && initialized) {
    return (
      <Container maxWidth={false} style={{ height: '100%' }}>
        <Grid container style={{ marginTop: 15 }} direction="row" spacing={isWidthDown('md', width) ? 1 : 3}>
          <Hidden smDown>
            <Grid item md={3} lg={3}>
              <StickyBox offsetTop={90} offsetBottom={0}>
                <Grid container style={{ maxWidth: 320 }}>
                  <Filters />
                  <Commercial />
                </Grid>
              </StickyBox>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={9} lg={9}>
            <Container maxWidth="lg">
              <Grid container direction="row" justify="space-between" alignItems="center" style={{ marginBottom: 40 }}>
                {
                  location.pathname === routes.WISHLIST && (
                    <Grid item container alignContent="center" style={{ marginBottom: 10 }}>
                      <RouterLink to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                        <ArrowBackIcon color="primary" style={{ fontSize: '1.5rem', marginRight: 5 }} />
                        <Typography color="primary" variant="subtitle1" style={{ fontWeight: 'bold' }} component="span">{t('contest_list.backToHome')}</Typography>
                      </RouterLink>
                    </Grid>
                  )
                }
                <Grid item xs="auto">
                  <Typography color="primary" variant="h2" gutterBottom>
                    {title()}
                    {resultsCount()}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Grid item>
                    <Select
                      fullWidth
                      label={t('contest_list.sort_by')}
                      items={[
                        { name: 'popularity', value: '1', label: `${t('contest_list.sort_by_news')}`, onClick: () => sortBy('publishedAt', 'desc') },
                        { name: 'old', value: '2', label: `${t('contest_list.sort_by_contests')}`, onClick: () => sortBy('template', 'asc') },
                        { name: 'creation', value: '3', label: `${t('contest_list.sort_by_date')}`, onClick: () => sortBy('publishedAt', 'asc') }
                      ]}
                      variant="outlined"
                      defaultValue='1'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="column">
                {results.length > 0 &&
                  <Grid container direction="row" spacing={4} id="contestListGrid">
                    {
                      results.slice(0, infiniteScroll.offset).map((contest, index) => {
                        const nextIndex = index + 1;
                        const canDisplaySponsor = index > 0 && nextIndex % frequency === 0 && (nextIndex / frequency) - 1 < related.length;
                        return (
                          <React.Fragment key={`contestGroup-${contest.hashId}`}>
                            <Grid className={`contest-${contest.hashId}`} item xs={12} sm={6} md={4} lg={3}>
                              <ContestCard
                                type={contest.type}
                                isLikeable={!user.isGuest}
                                id={contest.hashId}
                                key={contest.hashId}
                                isFavorite={contest.isLiked}
                                toggleFavorite={() => toggleFavorite(contest, !contest.isLiked)}
                                title={contest.title}
                                template={contest.categories.template}
                                image={contest.showcaseImage}
                                blog={contest.contentLink}
                                onClick={(event) => { handleToggle(contest.hashId, event); }}
                                templateLabel={contest.templateLabel}
                              />
                            </Grid>
                            { canDisplaySponsor &&
                              <Grid className="relatedContent" item xs={12} sm={6} md={4} lg={3}>
                                {displayRelatedContent((nextIndex / frequency) - 1)}
                              </Grid>
                            }
                          </React.Fragment>
                        );
                      })
                    }
                  </Grid>
                }
              </Grid>
              {/* Load More component */}
              {!infiniteScroll.fakeLoading && infiniteScroll.offset < results.length &&
                <Grid container justify="center" style={{ margin: '60px 0px 60px' }}>
                  <Grid container item xs={6} sm={4} md={3} justify="center">
                    <Grid item>
                      <Typography gutterBottom variant="subtitle1">
                        {t('contest_list.totalDisplayed', { count: infiniteScroll.offset, total: results.length })}
                        </Typography>
                    </Grid>
                    <Grid item style={{ width: '100%' }} xs={12} md={9}>
                      <LinearProgress variant="determinate" color="primary" value={(infiniteScroll.offset / results.length) * 100} style={{borderRadius: 5}} />
                    </Grid>
                    <Grid item xs={12} lg={9}>
                      <Button
                        variant="outlined"
                        color="default"
                        label={t('contest_list.loadMore')}
                        id="loadMore"
                        onClick={loadMore}
                        withArrow
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              }

              {/* Contact button when all results have been fetched */}
              {infiniteScroll.offset > results.length &&
                <Grid container justify="center" alignItems="center" style={{ margin: '80px 0px 120px' }}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      component="p"
                      color="textSecondary"
                      style={{ fontWeight: 'bold' }}
                      align="center"
                    >
                      {t('contest_list.endList')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="p" color="textSecondary" align="center">{t('contest_list.customBenchmark')}</Typography>
                  </Grid>
                  <Grid item xs={10} sm={6} md={4}>
                    <Button
                      variant="outlined"
                      color="default"
                      label={t('contest_list.contactUs')}
                      onClick={() => { window.open('https://www.kimpleapp.com/contact/'); }}
                      fullWidth
                      withArrow
                    />
                  </Grid>
                </Grid>
              }
              {/* Fake loader */}
              {infiniteScroll.fakeLoading &&
                <Grid container justify="center" alignItems="center" style={{ minHeight: 'inherit', margin: '60px 0px' }}>
                  <Loader />
                </Grid>
              }
              {
                open &&
                <Preview
                  handleClose={handleClose}
                  contest={contestPreview}
                  changeContest={changeContest}
                  open={open}
                  options={defaultOptions}
                  toggleFavorite={() => toggleFavorite(contestPreview, !contestPreview.isLiked)}
                  isFavorite={contestPreview.isLiked}
                  isLikeable={!user.isGuest}
                />
              }
            </Container>
          </Grid>
        </Grid>
        <BackToTop />
      </Container>
    );
  }

  if (!initialized && !error) {
    return (
      <Grid container justify="center" alignItems="center" style={{ minHeight: 'inherit' }}>
        <Loader />
      </Grid>
    );
  }

  if (error) {
    return (<p>Internal error</p>);
  }
}

ContestsList.defaultProps = {
  isWishlist: false
};

ContestsList.propTypes = {
  // isWishlist: PropTypes.bool
};

export default withWidth()(ContestsList);