import React from 'react';
import { Grid, Typography, Link, Hidden } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import TextField from 'components/form/inputs/TextField';
import Button from 'components/form/inputs/Button';
import { HeaderLogo } from 'components/general/KimpleLogo';
import { useContextUser } from 'components/utils/contexts/user/Context';

const useStyles = makeStyles((theme) => ({
  loginContainer: () => ({
    height: '100%',
    position: 'relative'
  }),
  guestLogin: {
    [theme.breakpoints.up('md')]: {
      marginBottom: 70
    }
  },
  loginTitle: () => ({
    color: theme.palette.secondary.main,
    // flexGrow: 1,
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      height: '100px',
      color: 'white',
    }
  }),
  backButton: () => ({
    position: 'absolute',
    top: 10,
    left: 10,
    color: 'white',
    cursor: 'pointer'
  })
}));

function LoginForm(props) {
  const { guestLogin } = useContextUser();
  const { handleChange, values, errors, handleSubmit, isSubmitting, width, backAction, submitCount, validateField } = props;
  const { t } = useTranslation('login');
  const classes = useStyles();

  const handleErrors = () => {
    return Object.keys(errors).length > 0 && submitCount > 0 && (
      <>
        <Grid item>
          <ErrorOutlineIcon color="error" style={{ marginRight: 5 }} />
        </Grid>
        <Grid item>
          <Typography color="error" variant="subtitle2" align="left">
            {errors.general || 'Le formulaire contient des erreurs de validation'}
          </Typography>
        </Grid>
      </>
    );
  };
  return (
    <Grid container alignItems="center" direction="column" justify="space-between" className={clsx(classes.loginContainer)}>
      <Grid container item alignItems="center" className={clsx(classes.loginTitle)}>
        <Hidden mdUp>
          <div className={clsx(classes.backButton)}>
            <Grid container onClick={backAction} alignItems="center">
              <ArrowBackIcon style={{ fontSize: isWidthDown('sm', width) ? 18 : 20, marginRight: 5 }} />
              <Typography>{t('backButton')}</Typography>
            </Grid>
          </div>
        </Hidden>
        <HeaderLogo color="inherit" />
        <Typography variant="body1" color="inherit">{t('pageTitle')}</Typography>
      </Grid>
      <Grid container item direction="row" justify="space-evenly" alignItems="center" className={clsx(classes.loginForm)}>
        <Grid container item xs={10} justify="center" className="GuestLogin">
          <form
            className="loginForm"
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            style={{ width: '100%' }}
          >
            <Typography variant="h1" color="primary" align={isWidthDown('sm', width) ? 'center' : 'left'}>
              {t('formTitle')}
            </Typography>
            <Typography color="primary" align={isWidthDown('sm', width) ? 'center' : 'left'} gutterBottom>
              {t('formSubtitle')}
            </Typography>
            <TextField
              label={t('fields.email')}
              margin="normal"
              name="email"
              type="text"
              variant="outlined"
              fullWidth
              value={values.email}
              onChange={handleChange}
              error={errors.email}
              onBlur={() => validateField('email')}
            />
            <TextField
              label={t('fields.password')}
              margin="normal"
              name="password"
              type="password"
              variant="outlined"
              fullWidth
              value={values.password}
              onChange={handleChange}
              error={errors.password}
              onBlur={() => validateField('password')}
            />
            <Typography color="textPrimary" variant="subtitle2" align="right">
              <Link href="https://dashboard.kimpleapp.com/lost-password" color="inherit" target="_blank">
                {t('forgottenPassword')}
              </Link>
            </Typography>
            <Grid className="ErrorContainer" container alignItems="center" direction="row">
              {handleErrors()}
            </Grid>
            <Grid container justify="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                label={t('fields.submit')}
                isSubmitting={isSubmitting}
                style={{ padding: '10px 40px 10px 50px' }}
                size="large"
                withArrow
              />
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Grid container item direction="row" justify="space-evenly" alignItems="center" className={clsx(classes.guestLogin)}>
        <Grid container item justify="center">
          <Grid item xs={10}>
            <Typography variant="body1" color="textPrimary" align="center">
              {t('noAccount')}
              <br />
              <Typography variant="body2" color="primary" component="span">
                <Link href="https://www.kimpleapp.com/contact/" target="_blank">
                  {t('appointment')}
                </Link>
              </Typography> {t('subscribeOffers')}
              <br />
              {t('guestMode')}
              <br />
            </Typography>
          </Grid>
          <Button
            variant="outlined"
            color="primary"
            label={t('loginAsGuest')}
            id="loginAsGuest"
            onClick={guestLogin}
            style={{ padding: '10px 40px' }}
            withArrow
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
LoginForm.defaultProps = {
  backAction: () => { }
};

LoginForm.propTypes = {
  errors: PropTypes.shape({ email: PropTypes.string, password: PropTypes.string, general: PropTypes.string }).isRequired,
  values: PropTypes.shape({ email: PropTypes.string, password: PropTypes.string, general: PropTypes.string }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  backAction: PropTypes.func,
  validateField: PropTypes.func.isRequired
};

export default withWidth()(LoginForm);
