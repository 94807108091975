import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Typography, Chip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: 5,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: '#EEE6F5',
    color: '#701f8f'
  },
  label: {
    lineHeight: 1
  },
  formControl: {
    alignItems: 'flex-start',
    '& .MuiButtonBase-root': {
      padding: '0px 9px'
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: 8
    },
    marginRight: 5
  },
  thumbnailContainer: {
    verticalAlign: 'middle',
    marginLeft: 5
  },
  thumbnail: {
    width: 20
  }
}));

function FilterCheckbox({ name, label, color, checked, onChange, count, thumbnail, id }) {
  const classes = useStyles();
  return (
    <FormControlLabel
      id={id}
      classes={{
        root: classes.formControl,
        label: classes.label
      }}
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          name={name}
          color={color}
        />
      }
      label={
        <div>
          <Typography
            variant="subtitle2"
            component="span"
            color={checked ? color : 'textSecondary'}
            style={checked ? { fontWeight: 700 } : {}}
          >
            {label}
          </Typography>
          { thumbnail && (
            <span className={classes.thumbnailContainer}>
              <img src={thumbnail} alt="Filter thumbnail" className={classes.thumbnail} />
            </span>
          )}
          {checked &&
            <Chip className={clsx(classes.chip, 'results__count')} label={count} color="primary" size="small" />
          }
        </div>
      }
    />
  );
}

FilterCheckbox.defaultProps = {
  name: 'checkbox',
  label: 'Checkbox',
  color: 'primary',
  checked: false,
  count: null,
  thumbnail: null,
  id: ''
};

FilterCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  count: PropTypes.number,
  thumbnail: PropTypes.string,
  id: PropTypes.string
};

export default FilterCheckbox;