import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton, Icon } from '@material-ui/core';
import PropTypes from 'prop-types';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: () => ({
    margin: '8px 0px',
    '& label.Mui-focused': {
      color: theme.palette.text.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.text.secondary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.text.secondary,
      },
    },
    '& .MuiFilledInput-underline:after': {
      borderColor: theme.palette.text.secondary
    }
  }),
}));

function CustomTextField({
  type,
  name,
  label,
  error,
  maxLength,
  fullWidth,
  color,
  variant,
  startIcon,
  required,
  onChange,
  onBlur,
  value
}) {
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();
  return (
    <TextField
      className={classes.root}
      autoFocus={false}
      variant={variant}
      required={required}
      value={value}
      fullWidth={fullWidth}
      onChange={onChange}
      onBlur={onBlur}
      type={
        type === 'password' && showPassword ? 'text' : type
      }
      name={name}
      label={label}
      error={error !== ''}
      color={color} 
      inputProps={{ maxLength: maxLength}} // eslint-disable-line
      // eslint-disable-next-line
      InputProps={{
        startAdornment: startIcon !== ''
          ? (<InputAdornment position="start">
            <Icon>
              {startIcon}
            </Icon>
          </InputAdornment>)
          : (''),
        endAdornment:
          type === 'password'
            ? (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  aria-label="Toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  id="togglePasswordVisibility"
                >
                  {showPassword ? (
                    <VisibilityOff />
                  ) : (
                      <Visibility />
                    )}
                </IconButton>
              </InputAdornment>
            )
            : (''),
      }}
    />
  );
}

CustomTextField.defaultProps = {
  label: 'TextField',
  error: '',
  value: '',
  fullWidth: false,
  maxLength: -1,
  color: 'primary',
  type: 'text',
  variant: 'standard',
  startIcon: '',
  required: false,
  onChange: () => {},
  onBlur: () => {}
};

CustomTextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
  startIcon: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

export default CustomTextField;