import React, { useState, useLayoutEffect } from 'react';
import { MdFirstPage } from 'react-icons/md';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  BackToTop: ({ hidden }) => ({
    display: 'inline-block',
    background: theme.palette.text.primary,
    width: 57,
    height: 57,
    position: 'fixed',
    transform: 'rotate(90deg)',
    right: 20,
    bottom: 90,
    cursor: 'pointer',
    borderRadius: 5,
    visibility: hidden ? 'hidden' : 'visible'
  }),
  icon: {
    color: 'white',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    top: '50%',
    left: '50%',
  }
}));

function BackToTop() {
  const [hidden, setHidden] = useState(true);
  const classes = useStyles({ hidden });

  const handleBackTopTopVisibility = () => {
    if (window.scrollY > 80 && hidden) {
      setHidden(false);
    } else if (window.scrollY < 80 && !hidden) {
      setHidden(true);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleBackTopTopVisibility);
    return () => window.removeEventListener('scroll', handleBackTopTopVisibility);
  }, [hidden]);

  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <div
      role="button"
      tabIndex={0}
      className={classes.BackToTop}
      onClick={scrollTop}
      onKeyPress={scrollTop}
    >
      <MdFirstPage size="3rem" className={classes.icon} />
    </div>
  );
}

export default BackToTop;
