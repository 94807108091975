import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import { Link, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  icon: ({ size }) => ({
    color: 'white',
    fontSize: size === 'large' ? '2.4rem' : '1.6rem'
  }),
  iconContainer: ({size}) => ({
    border: `1px solid ${theme.palette.light.main}`,
    padding: size === 'large' ? 12 : 6,
    margin: `0px ${size === 'large' ? 6 : 4}px`,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)'
    }
  })
}));

const SocialIcons = ({ size }) => {
  const classes = useStyles({ size });
  return (
    <>
      <Link href="https://www.facebook.com/kimpleapp/" target="_blank">
        <IconButton name="facebook" className={classes.iconContainer}>
          <FaFacebookF className={classes.icon} />
        </IconButton>
      </Link>
      <Link href="https://twitter.com/kimpleapp" target="_blank">
        <IconButton name="twitter" className={classes.iconContainer}>
          <FaTwitter className={classes.icon} />
        </IconButton>
      </Link>
      <Link href="https://www.linkedin.com/company/kimple/" target="_blank">
        <IconButton name="linkedin" className={classes.iconContainer}>
          <FaLinkedinIn className={classes.icon} />
        </IconButton>
      </Link>
    </>
  );
};

SocialIcons.defaultProps = {
  size: 'large'
};

SocialIcons.propTypes = {
  size: PropTypes.oneOf(['small', 'large'])
};

export default SocialIcons;