import { noAuth } from 'services/axios';

const getContests = () => noAuth.get('/frontend/inspiration_center/list?limit=1000');

const getOptions = () => noAuth.get('/frontend/inspiration_center/option');

const getRelatedContent = () => noAuth.get('/frontend/inspiration_center/linkedContent');

export default {
  getContests,
  getOptions,
  getRelatedContent
};