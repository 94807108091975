import types from './types';

const initSearchFetching = () => ({
  type: types.INIT_SEARCH_FETCHING,
});

const initSearchSuccess = (payload) => ({
  type: types.INIT_SEARCH_SUCCESS,
  payload
});

const initSearchFailed = (error) => ({
  type: types.INIT_SEARCH_FAILED,
  payload: error
});

const triggerSearch = (term) => ({
  type: types.SEARCH,
  payload: term
});

const updateFilters = () => ({
  type: types.UPDATE_FILTERS
});

const updateResults = () => ({
  type: types.UPDATE_RESULTS,
});

const addSearchHistory = (term) => ({
  type: types.UPDATE_SEARCH_HISTORY,
  payload: term
});

const toggleFilter = (categoryName, filterId) => ({
  type: types.TOGGLE_FILTER,
  payload: {
    categoryName,
    filterId
  }
});

const resetCategory = (category) => ({
  type: types.RESET_CATEGORY,
  payload: category
});

const updateWishlist = (contest) => ({
  type: types.UPDATE_WISHLIST,
  payload: contest
});

const updateStore = () => ({
  type: types.UPDATE_SEARCH_STORE
});

const resetFilters = () => ({
  type: types.RESET_FILTERS
});

const sortBy = (key, order = 'asc') => ({
  type: types.SORT_BY,
  payload: { key, order }
});

const saveSearchTerm = (term) => ({
  type: types.SAVE_SEARCH_TERM,
  payload: term
});

const firePredictions = (term) => ({
  type: types.GET_PREDICTIONS,
  payload: term
});

const clearPredictions = () => ({
  type: types.CLEAR_PREDICTIONS
});

export default {
  initSearchFetching,
  initSearchSuccess,
  initSearchFailed,
  triggerSearch,
  updateFilters,
  updateResults,
  toggleFilter,
  resetCategory,
  updateWishlist,
  updateStore,
  resetFilters,
  sortBy,
  addSearchHistory,
  saveSearchTerm,
  firePredictions,
  clearPredictions
};