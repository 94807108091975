import React from 'react';
import Particles from 'react-tsparticles';
import particlesOptions from 'config/tsparticles';

const KimpleParticles = () => {
  return (
    <Particles
      id="tsparticles"
      className="particles-wrapper"
      options={particlesOptions}
    />
  );
};

export default KimpleParticles;