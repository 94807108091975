import React, { useState } from 'react';
import { Grid, Hidden, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'components/form/inputs/Button';
import LoginForm from 'components/form/LoginForm';
import Particles from 'components/layouts/Particles';
import Slideshow from 'components/carousel/Slideshow';
import KimpleLogo from 'components/general/KimpleLogo';
import SocialIcons from 'components/general/Social';

const useStyles = makeStyles((theme) => ({
  gradient: () => ({
    [theme.breakpoints.down('sm')]: {
      padding: 0
    },
    padding: '8px 32px',
    background: `linear-gradient(to bottom right, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
    height: '100%',
    position: 'relative',
  }),
  paper: () => ({
    background: 'white',
    padding: '8px 32px',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      padding: theme.spacing(0),
    },
  }),
  overlay: () => ({
    height: '100%',
    zIndex: 10,
    padding: '12px 24px 48px'
  }),
  slideShowContainer: () => ({
    width: '100%',
    zIndex: 10
  }),
  socialIcons: {
    zIndex: 10,
    marginLeft: 10,
    marginTop: 15
  }
}));
function Login({ width, errors, isSubmitting, handleSubmit, values, handleChange, submitCount, validateField }) {
  const classes = useStyles();
  const [overlayVisible, setOverlayVisible] = useState(true);
  const { t } = useTranslation('login');

  const loginForm = () => (
    <Grid
      container
      item
      xs={12}
      md={5}
      justify="center"
      alignItems="center"
      className={classes.paper}>
      <LoginForm
        backAction={() => setOverlayVisible(true)}
        errors={errors}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        values={values}
        handleChange={handleChange}
        submitCount={submitCount}
        validateField={validateField}
      />
    </Grid>
  );

  const overlay = () => {
    if (overlayVisible) {
      return (
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
          className={classes.overlay}>
          <Grid direction="column" container item style={{ color: 'white' }} alignItems="center">
            <KimpleLogo size={46} color="inherit" />
            <Typography variant="body1" color="inherit">{t('pageTitle')}</Typography>
          </Grid>
          <Grid container item justify="center">
            <Slideshow />
            <Grid item xs={10} sm={5} justify="center">
              <Button fullWidth label={t('continue')} variant="contained" onClick={() => setOverlayVisible(false)} style={{padding: '10px 15px', marginBottom: 100}}/>
            </Grid>
          </Grid>
          <Grid item style={{ color: 'white ' }}>
            <SocialIcons />
          </Grid>
          <Grid item style={{ color: 'white ' }}>
            <Typography variant="body1" color="inherit" align="center">
              {t('noAccount')}&nbsp;
              <Typography variant="body2" color="inherit" component="span">
                <Link href="https://www.kimpleapp.com/contact/" color="inherit" target="_blank">
                  {t('appointment')}
                </Link>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return loginForm();
  };

  return (
    <Grid container direction="row">
      <Hidden smDown>
        {loginForm()}
      </Hidden>
      <Grid container item xs={12} md={7} justify="center" alignItems="center" className={classes.gradient}>
        <Particles />
        {
          isWidthDown('sm', width)
            ? overlay()
            : (
              <Grid container style={{ height: '100%' }}>
                <Grid item className={classes.socialIcons}>
                  <SocialIcons />
                </Grid>
                <Grid item className={classes.slideShowContainer}>
                  <div id="kimple-slideshow">
                    <Slideshow />
                  </div>
                </Grid>
              </Grid>
            )
        }
      </Grid>
    </Grid>
  );
}

Login.defaultProps = {
  errors: { email: '', password: '' },
  values: { email: '', password: '' },
  isSubmitting: false
};


Login.propTypes = {
  errors: PropTypes.shape({ email: PropTypes.string, password: PropTypes.string }),
  values: PropTypes.shape({ email: PropTypes.string, password: PropTypes.string }),
  isSubmitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  submitCount: PropTypes.number.isRequired,
  validateField: PropTypes.func.isRequired
};

export default withWidth()(Login);
