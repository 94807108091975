import React, { useState, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Avatar, CardHeader, CardContent, Card, Link, ClickAwayListener } from '@material-ui/core';

import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import OpenInNew from '@material-ui/icons/OpenInNew';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { Link as RouterLink } from 'react-router-dom';

import { useContextUser } from 'components/utils/contexts/user/Context';

const useStyles = makeStyles((theme) => {
    const avatarSize = 52;
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            position: 'relative',
            height: avatarSize + 15,
            zIndex: 10
        },
        card: {
            width: 300,
            boxShadow: '0px 2px 6px #00000066',
            position: 'absolute',
            right: 0,
            top: avatarSize + 15,
        },
        header: {
            textAlign: 'center',
            borderBottom: '1px solid #CECECE',
        },
        accountLink: {
            cursor: 'pointer',
            '&:hover': {
                fontWeight: 'bold',
                textDecoration: 'none',
            },
        },
        icons: {
            verticalAlign: 'middle',
            padding: 5
        },
        avatar: () => ({
            backgroundColor: theme.palette.secondary.main,
            width: avatarSize,
            height: avatarSize,
            [theme.breakpoints.down('md')]: {
                width: 42,
                height: 42
            }
        }),
    };
});

function CustomCard() {
    const classes = useStyles();
    const { user, logout } = useContextUser();
    const [isShown, setIsShown] = useState(false);
    const { t } = useTranslation('account');

    const getInitials = () => {
        if (user.data) {
            const { firstname, lastname } = user.data;
            if (firstname.length > 0 && lastname.length > 0) {
                return `${firstname.charAt(0)}${lastname.charAt(0)}`;
            }
        }
        return null;
    };

    const handleScroll = () => {
        if (isShown) setIsShown(false);
    };

    useLayoutEffect(() => {
        window.addEventListener('touchmove', handleScroll);
        return () => window.removeEventListener('touchmove', handleScroll);
    }, [isShown]);

    return (
        <ClickAwayListener onClickAway={() => setIsShown(false)}>
            <div onMouseLeave={() => setIsShown(false)} className={classes.root}>
                {user.data && (
                    <Avatar
                        className={classes.avatar}
                        onMouseEnter={() => { setIsShown(true); }}
                        onClick={() => { setIsShown(!isShown); }}
                        style={{ float: 'right' }}

                    >
                        {getInitials()}
                    </Avatar>
                )}
                {user.data && isShown && (
                    <Card className={classes.card}>
                        <CardHeader
                            className={classes.header}
                            title={<Typography color="primary" variant="body2">{user.data.firstname} {user.data.lastname}</Typography>}
                            subheader={<Typography color="primary" variant="body1">{user.data.client_name}</Typography>}
                        />
                        <CardContent>
                            <Grid container direction="row">
                                <Grid item sm={12}>
                                    <RouterLink to="/wishlist" style={{ textDecoration: 'none' }}>
                                        <Typography variant="subtitle2" color="textSecondary" className={classes.accountLink}>
                                            <FavoriteBorder color="inherit" className={classes.icons} />
                                            {t('favorite')}
                                        </Typography>
                                    </RouterLink>
                                </Grid>
                                <Grid item sm={12}>
                                    <Link href="http://dashboard.kimpleapp.com" variant="subtitle2" color="textSecondary" className={classes.accountLink}>
                                        <OpenInNew color="inherit" className={classes.icons} />
                                        {t('back_to_kimple')}
                                    </Link>
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography onClick={() => { logout(); }} variant="subtitle2" color="textSecondary" className={classes.accountLink}>
                                        <ExitToApp color="inherit" className={classes.icons} />
                                        {t('disconnect')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}
            </div>
        </ClickAwayListener>
    );
}

CustomCard.defaultProps = {
};

CustomCard.propTypes = {
};

export default CustomCard;