import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { IntercomProvider } from 'react-use-intercom';

import { UserProvider } from 'components/utils/contexts/user/Context';
import Router from 'components/utils/Router';
import theme from 'config/theme';
import i18n from 'config/i18n';
import './App.css';

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <IntercomProvider appId="r223z6qj" autoBoot={false}>
          <UserProvider>
            <Router />
          </UserProvider>
        </IntercomProvider>
      </I18nextProvider>
    </MuiThemeProvider>
  );
}

export default App;
