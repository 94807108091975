import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


const useStyles = makeStyles(() => ({
  container: () => ({
    minHeight: '100vh'
  }),
  innerContainer: () => ({
    minHeight: '100vh'
  })
}));

const MainContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container} justify="center" alignItems="center">
      <Grid container className={classes.innerContainer}>{children}</Grid>
    </Grid>
  );
};

MainContainer.propTypes = {
  children: PropTypes.element.isRequired
};

export default MainContainer;
