import { noAuth, withAuth, authInstance } from 'services/axios';

const login = (email, password) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);
  return noAuth.post('/login', formData);
};

const refreshToken = (token) => noAuth.post('/refresh', {}, {
  headers: {
    token
  }
});

const getCurrentUser = () => {
  return withAuth.get('/accounts/current');
};

const addFavorite = (hashId) => {
  const formData = new FormData();
  formData.append('hash_id', hashId);
  return withAuth.post('/accounts/like', formData);
};

const removeFavorite = (hashId) => {
  return withAuth.delete(`/accounts/like/${hashId}`);
};

const mfaVerify = (code, email, userHashId) => {
  const formData = new FormData();
  formData.append('hash_id', userHashId);
  formData.append('email', email);
  formData.append('code', code);
  return authInstance.post('/mfa/check', formData);
};

export default {
  login,
  getCurrentUser,
  addFavorite,
  removeFavorite,
  refreshToken,
  mfaVerify
};
