import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Typography, CircularProgress, Icon } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: ({ rounded }) => {
    return {
      overflow: 'hidden',
      transition: 'width .4s ease-out',
      // width: 'auto',
      marginTop: 15,
      marginBottom: 20,
      borderRadius: rounded ? 30 : 4,
      '& .MuiButton-endIcon': {
        maxWidth: 0,
        overflow: 'hidden',
        opacity: 0,
        transition: 'all .4s ease-out',
      },
      '&:hover': {
        '& .MuiButton-endIcon': {
          maxWidth: 100,
          opacity: 1
        }
      }
    };
  },
  contained: ({ color }) => ({
    color: color === 'default' && theme.palette.primary.main,
  }),
  outlined: ({ color }) => ({
    '&:hover': {
      background: theme.palette[color].main,
      color: theme.palette[color].contrastText
    }
  }),
}));

function getIcon(startIcon) {
  if (startIcon !== '') {
    return <Icon>{startIcon}</Icon>;
  }
  return '';
}

function CustomButton(props) {
  const { type, label, isSubmitting, variant, color, size, fullWidth, onClick, disabled, startIcon, style, id, withArrow } = props;
  const classes = useStyles(props);
  return (
    <Button
      type={type}
      id={id}
      variant={variant}
      color={color}
      classes={{
        root: clsx(classes.root),
        label: clsx(classes.label),
        contained: clsx(classes.contained),
        outlined: clsx(classes.outlined),
      }}
      disabled={disabled || isSubmitting}
      disableElevation
      size={size}
      fullWidth={fullWidth}
      onClick={onClick}
      startIcon={getIcon(startIcon)}
      endIcon={withArrow && <ChevronRightIcon className={classes.endIcon} />}
      style={style}
    >
      { type === 'submit' && isSubmitting ?
        <CircularProgress size={24} />
        :
        <Typography variant="button">{label}</Typography>
      }
    </Button>
  );
}

CustomButton.defaultProps = {
  type: 'button',
  label: 'Button',
  id: '',
  variant: 'contained',
  isSubmitting: false,
  color: 'default',
  size: 'medium',
  fullWidth: false,
  startIcon: '',
  onClick: null,
  disabled: false,
  style: {},
  withArrow: false
};

CustomButton.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  variant: PropTypes.string,
  isSubmitting: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  startIcon: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  withArrow: PropTypes.bool
};

export default CustomButton;