import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { FaRegTrashAlt } from 'react-icons/fa';

import Checkbox from 'components/form/inputs/FilterCheckbox';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    marginTop: 8
  },
  chip: {
    paddingLeft: 2,
    paddingRight: 2,
    height: 18
  },
  chipLabel: {
    fontSize: '1.1rem'
  },
  icon: {
    verticalAlign: 'top',
    fontSize: '1.5rem',
    color: theme.palette.primary.main,
    marginRight: 3
  },
  accordion: {
    boxShadow: 'none',
    border: '1px solid #CECECE',
    padding: '10px 0px'
  },
  categoryFilters: (collapsed) => ({
    padding: '0px 0px 0px 12px',
    position: 'relative',
    '&::after': {
      content: '""',
      display: collapsed ? 'none' : 'block',
      background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.1) 50%);',
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: 10,
    },
    '&:hover': {
      '&::after': {
        display: 'none'
      }
    }
  }),
  accordionDetails: {
    padding: '0px 12px 0px 32px',
    [theme.breakpoints.only('md')]: {
      padding: '0px 12px 0px 5px'
    },
  },
  accordionSummary: {
    padding: '0px 12px 0px 26px',
    [theme.breakpoints.only('md')]: {
      padding: '0px 10px 0px 16px'
    },
    minHeight: '25px !important',
    '& .MuiAccordionSummary-content': {
      margin: 0
    },
    '& .MuiAccordionSummary-expandIcon': {
      padding: 0
    }
  },
  expandIcon: {
    padding: '0px 12px'
  },
  toggleCollapse: {
    textDecoration: 'underline',
    cursor: 'pointer',
    [theme.breakpoints.only('md')]: {
      marginLeft: 13
    }
  },
  collapseTitle: {
    marginTop: 12
  },
}));

function FilterCategory({ filters, name, title, toggleFilter, resetCategory, expanded, toggleAccordion }) {
  const { t } = useTranslation('main');
  const [collapsed, setCollapsed] = useState(false);
  const categoryFilters = collapsed ? filters : filters.slice(0, 6);
  const classes = useStyles(collapsed);
  const numberChecked = filters.reduce((acc, filter) => acc + filter.checked, 0);

  return (
    <Accordion className={clsx(classes.accordion)} expanded={expanded} onChange={toggleAccordion}>
      <AccordionSummary id={name} expandIcon={<ExpandMoreIcon className={clsx(classes.expandIcon)} />} className={clsx(classes.accordionSummary, 'FilterCategory')}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography color={expanded ? 'textPrimary' : 'textSecondary'} variant='subtitle1'>{title.toUpperCase()}</Typography>
          </Grid>
          {numberChecked > 0 &&
            <Grid item className="resetCategory">
              <Grid container alignItems="center">
                <FaRegTrashAlt className={clsx(classes.icon, 'resetCategory--icon')} onClick={(e) => {
                  e.stopPropagation();
                  resetCategory(name);
                }} />
                <Chip
                  color="primary"
                  size="small"
                  label={numberChecked}
                  classes={{
                    root: clsx(classes.chip),
                    label: clsx(classes.chipLabel)
                  }}
                />
              </Grid>
            </Grid>
          }
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={clsx(classes.accordionDetails)}>
        <Grid container direction="column">
          <Grid container item className={clsx(classes.categoryFilters)}>
            {categoryFilters.map((filter) => (
              <Grid className={clsx(classes.filterContainer)} xs={12} container item key={`${name}--${filter.id}`} alignItems="center">
                <Checkbox
                  label={filter.label}
                  checked={filter.checked}
                  id={`filter--${name}_${filter.id}`}
                  name={`${name}.${filter.id}`}
                  onChange={() => {
                    if (!collapsed) setCollapsed(true);
                    toggleFilter(name, filter.id);
                  }}
                  count={filter.count}
                  thumbnail={
                    name === 'template' ? `https://d2tygfsszdug5i.cloudfront.net/assets/images/vendor/contest/templates/${filter.id}.png`
                      : null
                  }
                />
              </Grid>
            ))}
          </Grid>
          <Grid container item className={clsx(classes.collapseTitle)} onClick={() => setCollapsed(!collapsed)}>
            <Typography name={`${name}-show-more`} variant="subtitle2" color="primary" className={clsx(classes.toggleCollapse)}>
              {collapsed ? t('filters.displayLess') : t('filters.displayMore')}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

const filtersType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired
});

FilterCategory.propTypes = {
  name: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(filtersType).isRequired,
  toggleFilter: PropTypes.func.isRequired,
  resetCategory: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleAccordion: PropTypes.func.isRequired
};

export default FilterCategory;
