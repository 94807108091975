import React, { useState } from 'react';
import { Grid, Hidden, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';

import OneTimePasswordForm from 'components/form/OneTimePasswordForm';
import Particles from 'components/layouts/Particles';
import Slideshow from 'components/carousel/Slideshow';
import KimpleLogo from 'components/general/KimpleLogo';
import SocialIcons from 'components/general/Social';


  const useStyles = makeStyles((theme) => ({
    gradient: () => ({
      [theme.breakpoints.down('sm')]: {
        padding: 0
      },
      padding: '8px 32px',
      background: `linear-gradient(to bottom right, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
      height: '100%',
      position: 'relative',
    }),
    paper: () => ({
      background: 'white',
      padding: '8px 32px',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: '100%',
        padding: theme.spacing(0),
      },
    }),
    overlay: () => ({
      height: '100%',
      zIndex: 10,
      padding: '12px 24px 48px'
    }),
    slideShowContainer: () => ({
      width: '100%',
      zIndex: 10
    }),
    socialIcons: {
      zIndex: 10,
      marginLeft: 10,
      marginTop: 15
    }
  }));
  function OneTimePassword({ width, errors, isSubmitting, handleSubmit, values, handleChange, submitCount, validateField }) {
    const [typeAuthentication, setTypeAuthentication] = useState('googleAuthenticator');
    const classes = useStyles();
  
    const loginForm = () => (
      <Grid
        container
        item
        xs={12}
        md={5}
        justify="center"
        alignItems="center"
        className={classes.paper}>
        <OneTimePasswordForm
          errors={errors}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          values={values}
          handleChange={handleChange}
          submitCount={submitCount}
          validateField={validateField}
          typeAuthentication={typeAuthentication}
          setTypeAuthentication={setTypeAuthentication}
        />
      </Grid>
    );
  
    return (
      <Grid container direction="row">
        <Hidden smDown>
          {loginForm()}
        </Hidden>
        <Grid container item xs={12} md={7} justify="center" alignItems="center" className={classes.gradient}>
          <Particles />
          {
            isWidthDown('sm', width)
              ? loginForm()
              : (
                <Grid container style={{ height: '100%' }}>
                  <Grid item className={classes.socialIcons}>
                    <SocialIcons />
                  </Grid>
                  <Grid item className={classes.slideShowContainer}>
                    <div id="kimple-slideshow">
                      <Slideshow />
                    </div>
                  </Grid>
                </Grid>
              )
          }
        </Grid>
      </Grid>
    );
  }
  
  OneTimePassword.defaultProps = {
    errors: { email: '', password: '' },
    values: { email: '', password: '' },
    isSubmitting: false
  };
  
  
  OneTimePassword.propTypes = {
    errors: PropTypes.shape({ email: PropTypes.string, password: PropTypes.string }),
    values: PropTypes.shape({ email: PropTypes.string, password: PropTypes.string }),
    isSubmitting: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    width: PropTypes.string.isRequired,
    submitCount: PropTypes.number.isRequired,
    validateField: PropTypes.func.isRequired
  };
  
  export default withWidth()(OneTimePassword);