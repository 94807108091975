import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Link } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import SocialIcons from 'components/general/Social';

const useStyles = makeStyles(theme => ({
  footerContainer: {
    background: theme.palette.text.primary,
    color: 'white',
    minHeight: 'inherit',
    height: '100%'
  },
  kimpleLogo: {
    width: 80
  },
  column: {
    [theme.breakpoints.up('md')]: {
      minHeight: 120,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 15,
    },
  },
  columnContent: {
    height: '100%'
  },
  noMargin: {
    margin: 0
  }
}));
function Footer({ width }) {
  const classes = useStyles();
  const { t } = useTranslation('main');

  return (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.footerContainer}>
      <Grid container direction="row" item xs={9} md={11} lg={9} justify={isWidthDown('xs', width) ? 'center' : 'space-between'} alignItems={isWidthDown('md', width) ? 'center' : 'flex-start'}>
        <Grid item xs={6} md={3}>
          <Grid container direction="column" justify="space-between" className={classes.column}>
            <Grid item>
            <img src={`${process.env.PUBLIC_URL}/assets/kimple-full.svg`} alt="Kimple" className={classes.kimpleLogo}/>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">Kimple © Copyright {new Date().getFullYear()}<br />Version 1.2</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={3}>
          <Grid container direction="column" justify="space-between" className={classes.column}>
            <Grid item><Typography variant="button">{t('footer.about')}</Typography></Grid>
            <Grid item>
              <Typography variant="subtitle2">
                <Link href="https://www.kimpleapp.com/about/" color="inherit" target="_blank">
                  {t('header.about_us')}
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                <Link href="https://blog.kimpleapp.com/" color="inherit" target="_blank">
                  {t('header.kimple_blog')}
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                <Link href="http://support.kimpleapp.com/" color="inherit" target="_blank">
                  {t('header.need_help')}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container direction="column" justify="space-between" className={classes.column} style={{marginBottom: 15}}>
            <Grid item><Typography variant="button">Contact</Typography></Grid>
            <Grid item><Typography variant="subtitle2">+33 (0)3 66 72 85 12<br />
              <Link href="mailto:contact@kimpleapp.com" color="inherit" target="_blank">
                contact@kimpleapp.com
                </Link>
            </Typography></Grid>
            <Grid item>
              <Typography variant="subtitle2">
                <Link href="https://www.kimpleapp.com/privacy" color="inherit" target="_blank">{t('footer.privacyPolicy')}</Link> | &nbsp;
              <Link href={`${process.env.PUBLIC_URL}/assets/MENTIONSLEGALES.pdf`} color="inherit" target="_blank">{t('footer.legalNotice')}</Link> | &nbsp;
              <Link href="https://www.kimpleapp.com/cgu" color="inherit" target="_blank">{t('footer.terms')}</Link> | &nbsp;
              <Link href="https://www.kimpleapp.com/politique-cookies" color="inherit" target="_blank">{t('footer.cookiePolicy')}</Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={3} className={classes.column} style={{marginBottom: 15}} alignItems={isWidthDown('md', width) ? 'center' : 'flex-start'}>
          <Typography variant="button">{t('header.follow_us')} : </Typography>
          <SocialIcons size="small" />
        </Grid>
      </Grid>
    </Grid >
  );
}

Footer.propTypes = {
  width: PropTypes.string.isRequired
};

export default withWidth()(Footer);
