// Types related to the search field
const RESET_SEARCH_TERM = 1;
const SEARCH = 2;
const GET_PREDICTIONS = 3;
const CLEAR_PREDICTIONS = 19;

// Types related to the filters
const TOGGLE_FILTER = 4;
const UPDATE_FILTERS = 5;
const RESET_FILTERS = 6;
const RESET_CATEGORY = 7;
const UPDATE_RESULTS = 8;

// Global types
const FETCH_OPTIONS = 9;
const FETCH_CONTESTS = 10;
const INIT_SEARCH_FETCHING = 11;
const INIT_SEARCH_SUCCESS = 12;
const INIT_SEARCH_FAILED = 13;

const UPDATE_WISHLIST = 14;
const UPDATE_SEARCH_STORE = 15;

const SORT_BY = 16;
const UPDATE_SEARCH_HISTORY = 17;
const SAVE_SEARCH_TERM = 18;

export default {
  RESET_SEARCH_TERM,
  SEARCH,
  GET_PREDICTIONS,
  CLEAR_PREDICTIONS,
  TOGGLE_FILTER,
  UPDATE_FILTERS,
  RESET_CATEGORY,
  RESET_FILTERS,
  UPDATE_RESULTS,
  FETCH_OPTIONS,
  FETCH_CONTESTS,
  INIT_SEARCH_FETCHING,
  INIT_SEARCH_SUCCESS,
  INIT_SEARCH_FAILED,
  UPDATE_WISHLIST,
  UPDATE_SEARCH_STORE,
  SORT_BY,
  UPDATE_SEARCH_HISTORY,
  SAVE_SEARCH_TERM
};