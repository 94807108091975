import React, { useState, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Link, SwipeableDrawer, ClickAwayListener, Button, Hidden } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { HeaderLogo } from 'components/general/KimpleLogo';
import SearchField from 'components/form/inputs/SearchField';
import Filters from 'components/pages/home/Filters';
import Commercial from 'components/general/Commercial';
import Account from 'components/general/Account';
import { useContextUser } from 'components/utils/contexts/user/Context';
// import Button from 'components/form/inputs/Button';

const useStyles = makeStyles((theme) => {
  return {
    searchIconContainer: {
      verticalAlign: 'center'
    },
    searchIcon: {
      fontSize: '3.5rem',
      margin: '0px 10px 0px 0px',
      cursor: 'pointer'
    },
    headerContainer: ({ small }) => ({
      color: 'white',
      padding: small ? '0px 60px' : '25px 60px',
      [theme.breakpoints.only('md')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 30,
        paddingRight: 30,
      },
      height: 'auto',
      transition: 'all 0.5s ease',
      width: '100%',
      background: `url('assets/navbar-illustrations.png'), linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      boxShadow: 'rgb(0, 0, 0) 0px -1px 8px 0px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 30px',
        background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
      },
      [theme.breakpoints.only('xs')]: {
        padding: '10px 15px',
      }
    }),
    searchContainer: {
      marginBottom: 20
    },
    headerLink: () => ({
      color: 'white',
      textDecoration: 'none'
    }),
    bodyDrawer: ({small}) => ({
      marginTop: small ? 80 : 120,
      padding: '16px 32px',
      maxWidth: '100%'
    }),
    drawerOpener: {
      fontSize: '2.5rem',
      marginRight: 5,
      alignSelf: 'flex-end'
    },
    mobileMenu: {
      padding: '0px 36px 16px'
    },
    menuItem: {
      '&:hover': {
        textDecoration: 'underline'
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0px 0px 35px'
      }
    },
    hiddenItem: {
      visibility: 'hidden'
    },
    joinKimple: {
      borderRadius: 5,
      borderColor: 'white',
      color: 'white',
      '&:hover': {
        color: theme.palette.primary.main,
        background: 'white'
      }
    }
  };
});

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

function Header({ width }) {
  const [small, setSmall] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const { user, logout } = useContextUser();
  const classes = useStyles({ small });
  const [drawerOpened, setDrawerOpened] = useState(false);
  const { t } = useTranslation('main');

  const headerTitle = () => (
    <RouterLink to="/browse" className={clsx(classes.headerLink)}>
      <Grid container alignItems="center" justify="center">
        <HeaderLogo color="inherit" />
        {
          !small && (
          <Hidden only="xs">
            <Typography variant="body1" align="center">{t('header.inspiration_center')}</Typography>
          </Hidden>
          )
        }
      </Grid>
    </RouterLink>
  );

  const menuItems = () => (
    <>
      <Grid item className={classes.menuItem}>
        <Typography variant="body2">
          <Link
            target="_blank"
            href="https://www.kimpleapp.com/about/"
            color="inherit">
            {t('header.about_us')}
          </Link>
        </Typography>
      </Grid>
      <Grid item className={classes.menuItem}>
        <Typography variant="body2">
          <Link
            target="_blank"
            href="https://blog.kimpleapp.com/"
            color="inherit">
            {t('header.kimple_blog')}
          </Link>
        </Typography>
      </Grid>
      { isWidthDown('sm', width) &&
        <Grid item className={classes.menuItem}>
          <Typography variant="body2">
            <Link
              target="_blank"
              href="https://www.kimpleapp.com/contact/"
              color="inherit">
              {t('commercial.meeting')}
            </Link>
          </Typography>
        </Grid>
      }
      { isWidthDown('sm', width) && user.isGuest &&
        <Grid item className={classes.menuItem}>
          <Typography variant="body2" onClick={logout} component="span" style={{ cursor: 'pointer' }}>
            {t('header.joinKimple')}
          </Typography>
        </Grid>
      }
    </>
  );

  const mobileLayout = () => (
    <ClickAwayListener onClickAway={() => setMenuOpened(false)}>
      <Grid container direction="column" justify="space-around" className={clsx(classes.headerContainer)}>
        <Grid container item direction="row" justify="space-between" alignItems="center">
          <Grid item xs={small ? 4 : 2}><MenuIcon style={{ fontSize: '4rem' }} onClick={() => setMenuOpened(!menuOpened)} /></Grid>
          <Grid container item xs={small ? 4 : 8} justify="center" className={clsx(menuOpened && classes.hiddenItem)}>
            {headerTitle()}
          </Grid>
          <Grid container item xs={small ? 4 : 2} className={clsx(menuOpened && classes.hiddenItem)} alignItems="center" justify="flex-end">
            {small && (
              <Grid item className={classes.searchIconContainer}>
                <SearchIcon className={classes.searchIcon} onClick={() => setDrawerOpened(!drawerOpened)}/>
              </Grid>
            )}
            <Grid item>
              <Account />
            </Grid>
          </Grid>
        </Grid>
        {menuOpened && (
          <Grid container direction="column" justify="center" className={clsx(classes.mobileMenu)}>
            { menuItems()}
          </Grid>
        )}
        {!menuOpened && !small && (
          <Grid container direction="row" alignItems="center" onClick={() => setDrawerOpened(!drawerOpened)} style={{ cursor: 'pointer' }}>
            {drawerOpened ? <RemoveCircleOutlineIcon className={clsx(classes.drawerOpener)} /> : <AddCircleOutlineIcon className={clsx(classes.drawerOpener)} />}
            <Typography variant="subtitle1">{t('header.search')}</Typography>
          </Grid>
        )}

        <SwipeableDrawer
          anchor="left"
          open={drawerOpened}
          onClose={() => setDrawerOpened(false)}
          onOpen={() => setDrawerOpened(true)}
          disableBackdropTransition={!iOS} 
          disableDiscovery={iOS}
        >
          <Grid container className={classes.bodyDrawer}>
            <Grid container item className={classes.searchContainer}>
              <Typography color="primary" variant="h1" style={{ width: '100%' }}>{t('header.search')}</Typography>
              <SearchField placeholder={t('header.searchPlaceholder')} fullWidth searchCallback={() => setDrawerOpened(false)}/>
            </Grid>
            <Grid container item>
              <Typography color="primary" variant="h1">{t('filters.advanced_search')}</Typography>
              <Filters closeDrawer={() => setDrawerOpened(false)} />
              <Commercial />
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Grid>
    </ClickAwayListener>
  );

  const desktopLayout = () => (
    <Grid container direction="row" className={clsx(classes.headerContainer)} justify="space-between" alignItems="center">
      <Grid item xs="auto">
        {headerTitle()}
      </Grid>
      <Grid container item md={6} lg={7} direction="row" alignItems="center" justify="space-around">
        {menuItems()}
        <Grid item md={5}>
          <SearchField placeholder={t('header.searchPlaceholder')} />
        </Grid>
      </Grid>
      <Grid item md="auto">
        {user.isGuest
          ? (
              <Button variant="outlined" className={classes.joinKimple} color="inherit" size="medium" onClick={logout}>{t('header.joinKimple')}</Button>
          )
          : (<Account />)
        }
      </Grid>
    </Grid>
  );

  const handleScroll = () => {
    if (document.documentElement.scrollTop > 80 && !small) {
      setSmall(true);
      setMenuOpened(false);
    }
    else if (document.documentElement.scrollTop === 0) {
      setSmall(false);
    };
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [small]);

  return isWidthDown('sm', width) ? mobileLayout() : desktopLayout();
}

Header.propTypes = {
  width: PropTypes.string.isRequired,
};

export default withWidth()(Header);