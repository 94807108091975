const BROWSE = '/browse';
const WISHLIST = '/wishlist';
const LOGIN = '/login';
const ONETIMEPASSWORD = '/auth/mfa';
const COMPONENTS = '/components';

export default {
  BROWSE,
  WISHLIST,
  LOGIN,
  ONETIMEPASSWORD,
  COMPONENTS
};