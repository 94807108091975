import React, { useState, useRef, useEffect } from 'react';
import { Paper, InputBase, InputAdornment, Grid, Typography, ClickAwayListener, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { useSearchContext } from 'components/utils/contexts/search/Context';

const useStyles = makeStyles((theme) => {
  const placeholder = {
    color: theme.palette.text.placeholder,
    fontSize: '1.5rem',
  };
  return {
    root: ({ openedPanel }) => ({
      display: 'flex',
      alignItems: 'center',
      height: 44,
      width: '100%',
      borderRadius: openedPanel && '4px 4px 0px 0px',
      [theme.breakpoints.down('md')]: {
        boxShadow: 'none'
      },
    }),
    searchContainer: ({ fullWidth }) => ({
      position: 'relative',
      width: fullWidth ? '100%' : 'auto'
    }),
    searchIconEnd: {
      color: 'white'
    },
    inputBaseRoot: ({ openedPanel }) => ({
      paddingLeft: 20,
      flex: 1,
      height: '100%',
      borderRadius: openedPanel ? '4px 4px 0px 0px' : 4,
      [theme.breakpoints.down('sm')]: {
        border: '1px solid #cecece',
      },
    }),
    focusedInput: {
      borderColor: theme.palette.text.secondary
    },
    inputElement: {
      '&::-webkit-input-placeholder': placeholder,
      '&::-moz-placeholder': placeholder, // Firefox 19+
      '&:-ms-input-placeholder': placeholder, // IE 11
      '&::-ms-input-placeholder': placeholder, // Edge
    },
    iconButton: {
      background: theme.palette.primary.main,
      borderRadius: 0,
      borderBottomRightRadius: 4,
      borderTopRightRadius: 4,
      padding: '0px 15px',
      height: '100%',
      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
    searchLogo: {
      fill: theme.palette.text.secondary
    },
    clearSearch: {
      fill: theme.palette.text.secondary,
      cursor: 'pointer'
    },
    historyPanel: {
      width: 'calc(100% - 2px)',
      position: 'absolute',
      padding: '5px 0px',
      borderRadius: '0px 0px 4px 4px',
      zIndex: theme.zIndex.modal + 2,
      border: '1px solid #d2d2d2',
      [theme.breakpoints.down('sm')]: {
        borderTop: 0
      },
    },
    clockIcon: {
      marginRight: 10,
      fontSize: '1.8rem'
    },
    historyItem: {
      padding: '10px 0px',
      cursor: 'pointer'
    },
    spacing: {
      padding: '0px 20px'
    },
    predictionItem: {
      cursor: 'pointer',
      padding: '8px',
      borderBottom: '1px solid #80807d4d',
      '&:hover': {
        background: '#e6e6e6'
      }
    },
    predictionTitle: {
      fontSize: '1.2rem',
    },
    predictionDescription: {
      fontSize: '1rem'
    },
    predictionContent: {
      paddingLeft: 10
    },
    boldTerm: {
      fontWeight: 700
    },
    predictionIcon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginRight: 10
    },
    predictionTemplate: {
      marginBottom: 5
    },
    predictionResults: {
      fontWeight: 700,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  };
});

function SearchField({ placeholder, fullWidth, searchCallback }) {
  const { t } = useTranslation('main');
  const { search, triggerSearch, saveSearchTerm, firePredictions, clearPredictions } = useSearchContext();
  const [term, setTerm] = useState(search.term);
  const [showHistory, setShowHistory] = useState(false);
  const [showPredictions, setShowPredictions] = useState(false);
  const classes = useStyles({ fullWidth, openedPanel: (showPredictions && search.predictions.length > 0) || (showHistory && search.history.length > 0) });
  const inputRef = useRef();

  useEffect(() => {
    if (search.predictions.length > 0) {
      setShowPredictions(true);
      setShowHistory(false);
    }
  }, [search.predictions]);

  const clearSearch = () => {
    setTerm('');
    triggerSearch('');
    clearPredictions();
    inputRef.current.focus();
  };

  const handleChange = (searchTerm) => {
    const sanitizedSearchTerm = searchTerm.replace(/\\/g, "");
    setTerm(sanitizedSearchTerm);
    if (sanitizedSearchTerm.length >= 3) {
      firePredictions(sanitizedSearchTerm);
    } else if (search.predictions.length > 0) {
      clearPredictions();
    }
  };

  const createMarkup = (text, withLimit = false) => {
    const replace = `<span style="font-weight: 700;">${term}</span>`;
    if (withLimit && text.length > 150) {
      const position = text.search(new RegExp(term, 'gi'));
      if (position < 100) {
        const excerpt = `${text.slice(0, 150).replace(new RegExp(term, 'gi'), replace)}...`;
        return { __html: excerpt };
      }
      const firstPart = text.substring(0, 50).split(' ');
      const firstWords = firstPart.slice(0, firstPart.length < 5 ? firstPart.length : 5).join(' ');
      const secondPart = text.slice(position - 50, position + 100).split(' ');
      const finalWords = secondPart.slice(1, position + 100 > text.length ? secondPart.length : secondPart.length - 1).join(' ').replace(new RegExp(term, 'gi'), replace);
      const excerpt = `${firstWords} [...] ${finalWords}...`;
      return { __html: excerpt };
    }
    return { __html: text.replace(new RegExp(term, 'gi'), replace) };
  };

  return (
    <div className={clsx(classes.searchContainer)}>
      <Paper className={clsx(classes.root)} elevation={1}>
        <InputBase
          inputRef={inputRef}
          onClick={() => {
            if (search.predictions.length > 0) {
              setShowPredictions(true);
            } else {
              setShowHistory(true);
            }
          }}
          classes={{
            root: clsx(classes.inputBaseRoot),
            input: clsx(classes.inputElement),
            focused: clsx(classes.focusedInput)
          }}
          name="searchContest"
          placeholder={placeholder}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon className={clsx(classes.searchLogo)} />
            </InputAdornment>
          }
          endAdornment={
            term !== '' && (
              <InputAdornment id="clearSearchButton" position="start" onClick={clearSearch}>
                <CloseIcon className={clsx(classes.clearSearch)} />
              </InputAdornment>
            )
          }
          onChange={(e) => handleChange(e.target.value)}
          value={term}
          onKeyUp={(e) => {
            if (search.term !== term) saveSearchTerm(term);
            if (e.code === 'Enter') {
              setShowHistory(false);
              setShowPredictions(false);
              triggerSearch(term);
            }
          }}
        />
      </Paper>
      {
        showHistory && search.history.length > 0 && (
          <ClickAwayListener onClickAway={() => setShowHistory(false)}>
            <Paper className={classes.historyPanel}>
              <Grid container className={clsx(classes.spacing)} alignItems="center">
                <Grid item xs="auto">
                  <AccessTimeIcon color="primary" className={classes.clockIcon} />
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle2" color="primary" component="span">Mes dernieres recherches</Typography>
                </Grid>
              </Grid>
              <Grid container className={clsx(classes.spacing)}>
                {
                  search.history.map((item, index) => (
                    <Grid container item className={classes.historyItem} key={`searchHistory-${index + 1}`}>
                      <Typography
                        onClick={() => {
                          setTerm(item);
                          triggerSearch(item);
                          setShowHistory(false);
                        }}
                      >
                        {item}
                      </Typography>
                    </Grid>
                  ))
                }
              </Grid>
            </Paper>
          </ClickAwayListener>
        )
      }
      {
        showPredictions && search.predictions.length > 0 && (
          <ClickAwayListener onClickAway={() => setShowPredictions(false)}>
            <Paper className={classes.historyPanel} style={{ padding: 0 }} elevation={0}>
              <Grid container direction="column">
                {
                  search.predictions.map((prediction) => (
                    <Grid
                      container
                      direction="row"
                      item
                      key={`searchPrediction-${prediction.hashId}`}
                      alignItems="center"
                      className={classes.predictionItem}
                      onClick={() => {
                        triggerSearch(prediction.title);
                        setShowPredictions(false);
                        searchCallback();
                      }}
                    >
                      <Grid container item xs direction="column" className={classes.predictionContent}>
                        <Grid item>
                          <Typography gutterBottom className={classes.predictionTitle} dangerouslySetInnerHTML={createMarkup(prediction.title)} />
                        </Grid>
                        <Grid container item direction="row" alignItems="center" className={classes.predictionTemplate}>
                          <Grid item xs="auto">
                            <Avatar
                              src={`https://d2tygfsszdug5i.cloudfront.net/assets/images/vendor/contest/templates/${prediction.categories.template}.png`}
                              className={classes.predictionIcon}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2" className={classes.predictionDescription} dangerouslySetInnerHTML={createMarkup(prediction.templateLabel)} />
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.predictionDescription} dangerouslySetInnerHTML={createMarkup(prediction.description, true)} />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
                }
                <Grid container justify="flex-end" alignItems="center" style={{ padding: 12 }}>
                  <Grid item>
                    <Typography
                      gutterBottom
                      color="primary"
                      align="right"
                      variant="subtitle2"
                      className={classes.predictionResults}
                      component="span"
                      onClick={() => {
                        triggerSearch(term);
                        setShowPredictions(false);
                        searchCallback();
                      }}
                    >
                      {t('filters.seeAllResults', { term: term.length > 15 ? `${term.substring(0, 15)}...` : term })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </ClickAwayListener>
        )
      }
    </div>
  );
}

SearchField.defaultProps = {
  fullWidth: false,
  searchCallback: () => {}
};

SearchField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  searchCallback: PropTypes.func
};

export default withWidth()(SearchField);
