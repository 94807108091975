import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useContextUser } from 'components/utils/contexts/user/Context';

import routes from 'config/routes';
import PrivateRoute from 'components/utils/PrivateRoute';
import MainLayout from 'components/layouts/MainLayout';
import Login from 'components/containers/Login';
import NotFound from 'components/pages/NotFound';
import ContestsList from 'components/containers/ContestsList';
import OneTimePassword from 'components/containers/OneTimePassword';

export default function Router() {
  const { user } = useContextUser();
  const isAuthenticated = user.isGuest || user.data !== null;
  return (
    <Suspense fallback="">
      <MainLayout>
        <BrowserRouter>
          <Switch>
            { /* Default redirection */ }
            <Route exact path="/">
              <Redirect to={routes.BROWSE} />
            </Route>
            { /* Public routes */ }
            <Route
              exact path={routes.LOGIN}
              render={() =>
                isAuthenticated
                  ? <Redirect to={routes.BROWSE} />
                  : <Login />
              }
            />
              <Route
              exact path={routes.ONETIMEPASSWORD}
              isAuthenticated={isAuthenticated}
              isAuthorized
              component={OneTimePassword} />
            { /* Private routes with authentication */ }
            <PrivateRoute
              exact path={routes.BROWSE}
              isAuthenticated={isAuthenticated}
              isAuthorized
              component={ContestsList} />
            <PrivateRoute
              exact path={routes.WISHLIST}
              isAuthenticated={isAuthenticated}
              isAuthorized={!user.isGuest}
              component={ContestsList} />
            { /* Unmatched routes */ }
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </BrowserRouter>
      </MainLayout>
    </Suspense>
  );
}