import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Grid, Typography, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './custom.css';

function Slide({ content, title, legend }) {
  return (
    <Grid container justify="center">
      <Grid container item xs={10} md={9} lg={8} alignItems="center" style={{ color: 'white', padding: '12px 0px' }}>
        <Typography variant="h1" color="inherit" align="left">{title}</Typography>
        <Typography variant="body1" color="inherit" align="left" gutterBottom>{content}</Typography>
        <Typography variant="subtitle2" color="inherit" align="left">{legend}</Typography>
      </Grid>
    </Grid>
  );
}

Slide.defaultProps = {
  legend: null
};

Slide.propTypes = {
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  legend: PropTypes.element
};

function Slideshow() {
  const { t } = useTranslation('login');
  return (
    <Carousel
      autoPlay
      interval={6000}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      useKeyboardArrows={false}
      infiniteLoop
      swipeScrollTolerance={20}
    >
      <Slide title={t('slider.slide1.title')} content={t('slider.slide1.content')} />
      <Slide
        title={t('slider.slide2.title')}
        content={t('slider.slide2.content')}
        legend={
          <Link href={`${process.env.PUBLIC_URL}/assets/MENTIONSLEGALES.pdf`} target="_blank" color="inherit">
            {t('slider.slide2.legend')}
          </Link>
        }
      />
      <Slide title={t('slider.slide3.title')} content={t('slider.slide3.content')} />
    </Carousel>
  );
}

export default Slideshow;
