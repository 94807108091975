import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { ReactComponent as KimpleIcon } from 'kimple.svg';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const useStyles = makeStyles(() => ({
  root: ({ size }) => ({
    width: `${size}px`,
    height: `${size}px`,
    margin: '8px',
  }),
}));

function KimpleLogo(props) {
  const { size, color } = props;
  const classes = useStyles({ size });
  return (
    <SvgIcon component={KimpleIcon} viewBox="0 0 65 65" className={clsx(classes.root)} color={color} />
  );
}

KimpleLogo.defaultProps = {
  size: 30,
  color: 'primary'
};

KimpleLogo.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

function ResponsiveHeaderLogo({ width, color }) {
  return <KimpleLogo size={isWidthDown('sm', width) ? 46 : 66} color={color} />;
}

ResponsiveHeaderLogo.defaultProps = {
  color: 'primary'
};

ResponsiveHeaderLogo.propTypes = {
  width: PropTypes.string.isRequired,
  color: PropTypes.string
};

export const HeaderLogo = withWidth()(ResponsiveHeaderLogo);

export default KimpleLogo;