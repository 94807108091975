import React, { useEffect, useCallback } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import store from 'config/store';

import Login from 'components/pages/Login';
import { useContextUser } from 'components/utils/contexts/user/Context';

const validationSchema = (t) => {
  return Yup.object().shape({
    email: Yup.string()
      .email(t('invalidEmail'))
      .required(t('requiredEmail')),
    password: Yup.string().required(t('requiredPassword'))
  });
};

const LoginEnhancer = () => {
  const history = useHistory();
  const { login, shutdownIntercom, auth, getCurrentUser } = useContextUser();
  const { t } = useTranslation('login');

  const handleSubmit = useCallback(async (values, { setErrors, setSubmitting, setFieldError }) => {
    try {
      await login(values.email, values.password);
      if(!store.get('auth').mfaEnabled) {
        getCurrentUser()
        history.push('/');
      } if(store.get('auth').mfaEnabled) {
        history.push('/auth/mfa');
        setSubmitting(true);
      }
    }catch(error)  {
        const { response } = error;
        if (response) {
          const { status } = response;
          if (status === 401) {
            setFieldError('email', t('validations.badCredentials'));
            setFieldError('password', t('validations.badCredentials'));
            setFieldError('mfa', t('validations.badCredentials'));
            setErrors({ general: t('validations.badCredentials') });
          } else if (status === 400) {
            setErrors({ general: t('validations.unauthorized') });
          } else {
            setErrors({ general: t('validations.internalServerError') });
          }
        }
        setSubmitting(false);
 } }, []);

  useEffect(() => shutdownIntercom(), []);
  return (
    <Formik
      component={Login}
      initialValues={{
        email: '',
        password: '',
      }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(t)}
    />
  );
};

export default LoginEnhancer;