import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Select, InputLabel, MenuItem, FormControl} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#CECECE'
    },
    '& .MuiSelect-root': {
      color: theme.palette.text.placeholder
    }
  },
  menuItem: {
    color: theme.palette.text.placeholder
  },
  selectedItem: {
    backgroundColor: 'white !important',
    fontWeight: 700,
    color: theme.palette.primary.main
  }
}));

function CustomSelect({ label, defaultValue, items, variant, fullWidth }) {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl variant={variant} className={classes.formControl} fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <Select
        autoWidth={false}
        value={value}
        onChange={handleChange}
        IconComponent={KeyboardArrowDownIcon}
        label={label}
        className={classes.select}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        {items.map((item) => (
          <MenuItem 
          key={`Checkbox-${item.name}`} 
          value={item.value}
          onClick={item.onClick}
          classes={{
            root: classes.menuItem,
            selected: classes.selectedItem
          }}
          >{item.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

CustomSelect.defaultProps = {
  label: 'Label',
  defaultValue: '',
  items: [{ value: 'default', label: 'Selectionnez :' }],
  variant: 'filled',
  fullWidth: false
};

CustomSelect.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  })),
  fullWidth: PropTypes.bool
};

export default CustomSelect;