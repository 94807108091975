import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend) // passes i18n down to Backend
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    lng: 'fr',
    fallbackLng: 'fr',
    defaultNS: 'translation',
    whitelist: ['en', 'fr', 'pt'],
    debug: process.env.NODE_ENV !== 'production',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    nonExplicitSupportedLngs: true,
    load: 'languageOnly',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    ns: ['login', 'main', 'translation', 'preview'],
  });

export default i18n;