import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: '\'Poppins\', sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10,
    h1: {
      fontWeight: 700,
      fontSize: '3.1rem',
      margin: '8px 0px',
    },
    h2: {
      fontWeight: 700,
      fontSize: '2.8rem',
      margin: 0
    },
    h3: {
      fontSize: '2.6rem',
      fontWeight: 500,
      margin: '16px 0px'
    },
    h4: {
      fontSize: '2.4rem',
      fontWeight: 500,
      margin: '16px 0px'
    },
    h5: {
      fontSize: '2.2rem',
      fontWeight: 700,
      margin: '8px 0px'
    },
    h6: {
      fontSize: '2rem',
      fontStyle: 'italic',
      fontWeight: 400,
      // margin: '8px'
    },
    body1: {
      fontSize: '1.7rem',
      fontWeight: 400
    },
    body2: {
      fontSize: '1.7rem',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '1.5rem',
      fontWeight: 400
    },
    subtitle2: {
      fontSize: '1.4rem',
      fontWeight: 400
    },
    button: {
      fontSize: '1.7rem',
      fontWeight: 500,
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: '#5C1795',
      dark: '#701F8F',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FC5B5B',
      contrastText: '#fff'
    },
    light: {
      main: '#FFFFFF',
      dark: '#707070',
      contrastText: '#fff'
    },
    default: {
      main: '#5C1795',
      contrastText: '#fff'
    },
    // error: {

    // },
    // info: {

    // },
    // warning: {

    // },
    // success: {

    // },
    // background: {
      
    // },
    text: {
      primary: '#343031',
      secondary: '#8D8889',
      disabled: 'black',
      hint: 'black',
      placeholder: '#8D8889'
    },
  }
});

const options = {
  breakpoints: ['lg', 'xl'],
  factor: 1.5
};

export default responsiveFontSizes(theme, options);
