import propTypes from 'prop-types';
import React, { useState, createContext, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

import { withAuth } from 'services/axios';
import authService from 'services/auth';
import store from 'config/store';

const UserContext = createContext();

export function useContextUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useContextUser must be used within a UserProvider');
  }
  return context;
}

const initialUser = store.initialState.user;
const initialAuth = store.initialState.auth;

export const UserProvider = ({ children }) => {
  const { boot, shutdown, hardShutdown } = useIntercom();
  const sessionUser = store.get('user');
  const sessionAuth = store.get('auth');

  const [user, setUser] = useState(sessionUser || initialUser);
  const [auth, setAuth] = useState(sessionAuth || initialAuth);

  const addAuthorization = (token) => {
    withAuth.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  const bootIntercom = (authedUser) => {
    if (process.env.NODE_ENV === 'production') {
      boot({
        userId: authedUser.id,
        name: `${authedUser.firstname} ${authedUser.lastname}`,
        email: authedUser.email,
        createdAt: authedUser.created_at,
        phone: authedUser.phone,
        company: authedUser.client_name
      });
    }
  };

  const login = (email, password) => {
    return authService.login(email, password).then((loginResponse) => {
      store.reset();
      const loginData = loginResponse.data.data;
      const { username, token, expire } = loginData;
      const mfaEnabled = loginData.mfa_enable;
      const userHashId = loginData.user_hash_id;
      const refreshToken = loginData.refresh_token;
      const refreshTokenExpire = loginData.refresh_token_expire;
      setAuth({ username, token, expire, refreshToken, refreshTokenExpire, mfaEnabled, userHashId });
      store.set('auth', { username, token, expire, refreshToken, refreshTokenExpire, mfaEnabled, userHashId });
      addAuthorization(token)
    });
  };

  const getCurrentUser = () => {
      // Get current user endpoint
     return authService.getCurrentUser()
        .then((userResponse) => {
          const responseData = userResponse.data;
          const userData = responseData.data;
          // Save the user data inside the context
          const authenticatedUser = { ...initialUser, data: userData };
          setUser(authenticatedUser);
          store.set('user', authenticatedUser);
        });  
  }

  const addFavorite = (hashId) => {
    return authService.addFavorite(hashId);
  };

  const removeFavorite = (hashId) => {
    return authService.removeFavorite(hashId);
  };

  const logout = () => {
    shutdown();
    setAuth(initialAuth);
    setUser(initialUser);
    store.destroy();
  };

  const guestLogin = () => {
    store.reset();
    const guestUser = { ...store.initialState.user, isGuest: true };
    setUser(guestUser);
    setAuth(initialAuth);
    store.set('user', guestUser);
  };

  useEffect(() => auth.token && addAuthorization(auth.token), [auth]);
  useEffect(() => user.data && bootIntercom(user.data), [user]);

  return (
    <UserContext.Provider value={{ user, login, getCurrentUser, logout, auth, guestLogin, addFavorite, removeFavorite, shutdownIntercom: hardShutdown }}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: propTypes.element.isRequired
};

export default {
  useContextUser,
  UserProvider
};
