/* eslint-disable */
import _ from 'lodash';
import types from './types';
import store from 'config/store';
import routes from 'config/routes';

export const initialState = {
  route: routes.BROWSE,
  initialized: false,
  loading: false,
  error: false,
  search: {
    term: '',
    predictions: [],
    history: []
  },
  contests: [],
  options: null,
  defaultOptions: null,
  results: [],
  filters: [],
  order: [],
  wishlist: [],
  relatedContent: {
    list: [],
    results: [],
    frequency: 0
  }
};

export default (state, action) => {
  const { type, payload } = action;
  const enhanceOptions = (options) => {
    return Object.keys(options).map(key => {
      const categoryValues = options[key].map(option => {
        return { ...option, checked: false, count: 0 };
      });
      return { name: key, values: categoryValues }
    });
  }

  // Fetching search data => loading
  if (type === types.INIT_SEARCH_FETCHING) {
    return { ...state, loading: true, initialized: false };
  }

  // Once the search data has been fetched, the reducer recives an options / contests list in the payload
  else if (type === types.INIT_SEARCH_SUCCESS) {
    const { options, contests, wishlist, relatedContent, route } = payload;
    const enhancedOptions = enhanceOptions(options);
    const results = route === routes.WISHLIST ? wishlist : contests;
    const relatedResults = route === routes.WISHLIST ? [] : relatedContent.list;
    return {
      ...state,
      initialized: true,
      contests, results,
      options: [...enhancedOptions],
      defaultOptions: options,
      filters: [...enhancedOptions],
      loading: false,
      wishlist,
      relatedContent: {
        frequency: relatedContent.frequency || 0,
        list: relatedContent.list || [],
        results: relatedResults || []
      },
      route
    };
  }

  // If the search data fetch throws an error
  else if (type === types.INIT_SEARCH_FAILED) {
    return { ...state, initialized: false, loading: false };
  }

  // Reset totally the search data
  else if (type === types.RESET_SEARCH_TERM) {
    return { ...state, ...initialState.search };
  }

  // The search has been triggered with a search term as payload
  else if (type === types.SEARCH) {
    const term = action.payload;
    const regExp = new RegExp(`${term}`, 'i');
    const updatedResults = state.contests.filter(contest => contest.title.match(regExp) || contest.description.match(regExp));
    return { ...state, search: { ...state.search, term }, results: updatedResults };
  }

  // Update filters
  else if (type === types.UPDATE_FILTERS) {
    const updatedFilters = state.filters.map((category) => {
      const updatedValues = category.values.map(value => {
        const count = state.results.reduce((acc, contest) => {
          if (contest.categories.hasOwnProperty(category.name)) {
            if (contest.categories[category.name] === value.id) {
              return ++acc;
            }
          }
          return acc;
        }, 0)

        return { ...value, count };
      });
      return {
        ...category, values: updatedValues
      }
    });
    return { ...state, filters: updatedFilters };
  }

  // Once a filter has been toggled
  else if (type === types.TOGGLE_FILTER) {
    const { categoryName, filterId } = action.payload;
    const updatedFilters = [...state.filters].reduce((acc, category) => {
      if (category.name === categoryName) {
        const values = [...category.values].map((filter) => filter.id === filterId ? {...filter, checked: !filter.checked} : filter);
        return [...acc, {...category, values }];
      }
      return [...acc, category];
    }, []);
    return { ...state, filters: updatedFilters };
  }

  else if (type === types.UPDATE_RESULTS) {
    const { route, search } = state;
    const contests = route === routes.WISHLIST ? state.wishlist : state.contests;
    const related = route === routes.WISHLIST ? [] : state.relatedContent.list;
    const filters = state.filters;
    const categories = filters.map(category => {
      const name = category.name;
      const intersections = category.values.filter(filter => filter.checked).map(filter => ({ [name]: filter.id }));
      return { intersections, name };
    });

    const regExp = new RegExp(`${search.term}`, 'i');
    const results = _.filter(contests, (contest) => {
      return categories.map(({ intersections, name }) => {
        if (intersections.length === 0) return true;
        return _.intersectionBy([contest.categories], intersections, `${name}`).length > 0;
      })
        .every((currentValue) => currentValue === true)
    }).filter(contest => contest.title.match(regExp) || contest.description.match(regExp))

    const relatedContentFiltered = _.filter(related, (content) => {
      return categories.map(({ intersections, name }) => {
        if (intersections.length === 0) return true;
        return _.intersectionBy([content.categories], intersections, `${name}`).length > 0;
      })
        .every((currentValue) => currentValue === true)
    });

    return { ...state, results, relatedContent: { ...state.relatedContent, results: relatedContentFiltered } };
  }


  else if (type === types.RESET_CATEGORY) {
    const updatedFilters = state.filters;
    const index = state.filters.findIndex(category => category.name === payload);
    const category = index !== -1 ? state.filters[index] : null;
    if (category) {
      const updatedValues = category.values.map(filter => ({ ...filter, checked: false }));
      updatedFilters.splice(index, 1, { ...category, values: updatedValues })
    }
    return { ...state, results: state.contests, filters: updatedFilters };
  }

  else if (type === types.UPDATE_WISHLIST) {
    const contests = [...state.contests];
    const results = [...state.results];
    const updatedContest = { ...payload, isLiked: !payload.isLiked };

    const contestIndex = contests.findIndex(contest => contest.hashId === updatedContest.hashId);
    const resultIndex = results.findIndex(contest => contest.hashId === updatedContest.hashId);

    const updatedContests = [...contests.slice(0, contestIndex), updatedContest, ...contests.slice(contestIndex + 1)];
    const updatedResults = [...results.slice(0, resultIndex), updatedContest, ...results.slice(resultIndex + 1)];

    const updatedWishlist = updatedContest.isLiked ? [...state.wishlist, updatedContest] : state.wishlist.filter(contest => contest.hashId !== updatedContest.hashId);
    return { ...state, contests: updatedContests, results: updatedResults, wishlist: updatedWishlist };
  }

  else if (type === types.UPDATE_SEARCH_STORE) {
    const { contests, wishlist } = state;
    store.set('search', { contests, wishlist })
    return state;
  }

  else if (type === types.RESET_FILTERS) {
    const { route } = state;
    const contests = route === routes.WISHLIST ? state.wishlist : state.contests;
    return { ...state, filters: state.options, results: contests };
  }

  else if (type === types.SORT_BY) {
    const { key, order } = payload;
    let sortedResults = state.results.sort((a, b) => a[key] - b[key]);
    if (order === 'desc') sortedResults.reverse();
    return { ...state, results: sortedResults };
  }

  else if (type === types.UPDATE_SEARCH_HISTORY) {
    const { search } = state;
    const newHistory = [payload, ...search.history].slice(0, 3);
    return {...state, search: {
      ...state.search,
      history: newHistory
    }}
  }

  else if (type === types.SAVE_SEARCH_TERM) {
    return {...state, search: {
      ...state.search,
      term: payload
    }};
  }

  else if (type === types.CLEAR_PREDICTIONS) {
    return {
      ...state,
      search: {
        ...state.search,
        predictions: []
      }
    }
  }

  else if (type === types.GET_PREDICTIONS) {
    const { payload: searchTerm } = action;
    const regExp = new RegExp(`${searchTerm}`, 'i');
    const predictions = state.contests.filter(contest => contest.title.match(regExp) || contest.description.match(regExp) || contest.templateLabel.match(regExp)).slice(0, 3);
    return {
      ...state,
      search: {
        ...state.search,
        predictions
      }
    };
  }
  
  else {
    throw new Error(`Unexpected "${type}" action type`);
  }
};