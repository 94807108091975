import React from 'react';
import { Grid, Typography, Link, Hidden, Box } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useHistory } from 'react-router-dom';

import TextField from 'components/form/inputs/TextField';
import Button from 'components/form/inputs/Button';
import { HeaderLogo } from 'components/general/KimpleLogo';

const useStyles = makeStyles((theme) => ({
  oneTimePasswordContainer: () => ({
    height: '100%',
    position: 'relative'
  }),
  guestLogin: {
    [theme.breakpoints.up('md')]: {
      marginBottom: 70
    }
  },
  oneTimePasswordTitle: () => ({
    color: theme.palette.secondary.main,
    // flexGrow: 1,
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      height: '100px',
      color: 'white',
    }
  }),
  backButton: () => ({
    position: 'absolute',
    top: 10,
    left: 10,
    color: 'white',
    cursor: 'pointer'
  })
}));

function OneTimePasswordForm(props) {
  const { handleChange, values, errors, handleSubmit, isSubmitting, width, backAction, submitCount, validateField, typeAuthentication, setTypeAuthentication } = props;
  const { t } = useTranslation('login');
  const classes = useStyles();

  const handleChangeTypeAuthentication = (e) => {
    e.preventDefault();
    values.mfa = '';
    if (typeAuthentication === 'googleAuthenticator') {
      setTypeAuthentication('backupCode');
    } else {
      setTypeAuthentication('googleAuthenticator');
    }
  };

  const handleErrors = () => {
    return Object.keys(errors).length > 0 && submitCount > 0 && (
      <>
        <Grid item>
          <ErrorOutlineIcon color="error" style={{ marginRight: 5 }} />
        </Grid>
        <Grid item>
          <Typography color="error" variant="subtitle2" align="left">
            {errors.general || t('validations.validationFormErrors')}
          </Typography>
        </Grid>
      </>
    );
  };

  const history = useHistory();

  return (
    <Grid container alignItems="center" direction="column" justify="space-between" className={clsx(classes.oneTimePasswordContainer)}>
      <Grid container item alignItems="center" className={clsx(classes.oneTimePasswordTitle)}>
        <HeaderLogo color="inherit" />
        <Typography variant="body1" color="inherit">{t('pageTitle')}</Typography>
      </Grid>
      <Grid container item direction="row" justify="space-evenly" alignItems="center" className={clsx(classes.loginForm)}>
      <Grid container onClick={() => history.push('')}  alignItems="center" style={{ marginBottom: 40,  cursor: "pointer" }}>
            <ArrowBackIcon color="inherit" style={{ fontSize: isWidthDown('sm', width) ? 14 : 16, marginRight: 15 }} />
            <Typography variant="subtitle2">{t('backButton')}</Typography>
          </Grid>
        <Grid container item xs={10} justify="center" className="GuestLogin">
          <form
            className="loginForm"
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            style={{ width: '100%' }}
          >
            <Typography variant="h1" color="primary" align={isWidthDown('sm', width) ? 'center' : 'left'}>
            {t('mfa.formTitle')}
            </Typography>
            <Typography color="primary" align={isWidthDown('sm', width) ? 'center' : 'left'} gutterBottom>
            {typeAuthentication === 'backupCode' && t('mfa.formDescriptionGoogle')}
              {typeAuthentication === 'googleAuthenticator' && t('mfa.formDescriptionBackup')}
            </Typography>
            <TextField
              label={t('mfa.labelInput')}
              margin="normal"
              name="mfa"
              type="text"
              variant="outlined"
              fullWidth
              maxLength={typeAuthentication === 'googleAuthenticator' ? 6 : -1}
              value={values.mfa}
              onChange={handleChange}
              error={errors.mfa}
              onBlur={() => validateField('mfa')} 
            />
            <Typography color="textPrimary" variant="subtitle2" align="right">
            <Link  underline="always" href="https://dashboard.kimpleapp.com/lost-password" color="inherit" target="_blank" onClick={handleChangeTypeAuthentication}>
            <Box component="span">
            {typeAuthentication === 'backupCode' && t('mfa.buttonToGoogle')}
              {typeAuthentication === 'googleAuthenticator' && t('mfa.buttonToBackup')}
            </Box>
              </Link>
            </Typography>
            <Grid className="ErrorContainer" container alignItems="center" direction="row">
              {handleErrors()}
            </Grid>
            <Grid container justify="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                label={t('fields.submit')}
                isSubmitting={isSubmitting}
                style={{ padding: '10px 40px 10px 50px' }}
                size="large"
                withArrow
                disabled={!values.mfa}
              />
            </Grid>
          </form>
        </Grid>
      </Grid>
      <div />
    </Grid>
  );
}
OneTimePasswordForm.defaultProps = {
  backAction: () => { }
};

OneTimePasswordForm.propTypes = {
  errors: PropTypes.shape({ email: PropTypes.string, password: PropTypes.string, general: PropTypes.string, mfa: PropTypes.string }).isRequired,
  values: PropTypes.shape({ email: PropTypes.string, password: PropTypes.string, general: PropTypes.string, mfa: PropTypes.string }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  backAction: PropTypes.func,
  validateField: PropTypes.func.isRequired,
  typeAuthentication: PropTypes.string.isRequired,
  setTypeAuthentication: PropTypes.func.isRequired
};

export default withWidth()(OneTimePasswordForm);
